import CPMSService from "@/services/CPMSService";
import router from "@/router";

export const namespaced = true

export const state = {
  'user': null,
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    localStorage.setItem('user', JSON.stringify(userData))
    state.user = userData
  },
  CLEAR_USER_DATA() {
    localStorage.removeItem('user')
    localStorage.removeItem('active_project')
    location.replace(location.origin)
    // location.reload()
  },
}

export const actions = {
  logout({commit}) {
    commit('CLEAR_USER_DATA')
  },
  login({commit}, credentials) {
    return new Promise((resolve, reject) => {
      CPMSService.login(credentials).then(response => {
        const userData = response.data
        commit('SET_USER_DATA', userData)
        resolve(response)
      }).catch(error => {
        console.log(error.response)
        reject(error)
      })
    })
  },
  set_user_data({commit}, userData) {
    commit('SET_USER_DATA', userData)
  },
  register({commit}, credentials) {
    return new Promise((resolve, reject) => {
      CPMSService.register(credentials)
      .then(response => {
        commit('SET_USER_DATA', response.data)
        resolve(response)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
}

export const getters = {
  LoggedIn: state => {
    return !!state.user
  },
}