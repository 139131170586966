import axios from 'axios'
import url from 'url'
import store from "../store"

const origin = window.location.origin
const client = window.location.hostname.split('.')[0]
var api = origin.replace(client, client + ".api")
let apiClient = axios.create({
  baseURL: api,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.request.use(config => {
  const userData = store.state.user.user || JSON.parse(localStorage.getItem('user'))
  if (userData) {
    config.headers['Authorization'] = 'Token ' + userData['key']
  }
  return config
})

apiClient.interceptors.response.use(
  response => response, // simply return the response
  error => {
    console.log(error.response.status)
    if (error.response.status === 403) { // if we catch a 401 error
      store.dispatch('user/logout') // force a log out
    }
    return Promise.reject(error) // reject the Promise, with the error as the reason
  }
)
export default {
  login(credentials) {
    return new Promise((resolve, reject) => {
      apiClient.post('/dj-rest-auth/login/', credentials)
        .then(response => {
          resolve(response)
        }).catch(error => {
        reject(error)
      })
    })
  },
  register(credentials) {
    return new Promise((resolve, reject) => {
      apiClient.post('/dj-rest-auth/registration/', credentials)
        .then(response => {
          resolve(response)
        }).catch(error => {
        reject(error)
      })
    })
  },
  getProjectList() {
    return apiClient.get('/dzialki/api/project/')
  },
  getPlotList({offset, limit, search, extra = false} = {}) {
    const projet_id = store.state.project.active_project.projet_id || null
    let query = '/dzialki/api/parcelle/?search=' + search + "&limit=" + limit + "&offset=" + offset
    query = projet_id && !extra ? query + '&projet_id=' + projet_id : query
    return apiClient.get(query)
  },
  getPlotDetail(id) {
    return apiClient.get('/dzialki/api/parcelle/' + id)
  },
  updateStatutParcelle(data) {
    return apiClient.patch('/dzialki/api/parcelle/' + data.parcelle_id + '/', data)
  },
  addPlotComment(data) {
    return apiClient.post('/dzialki/api/commentaire-parcelle/', data)
  },
  deletePlotComment(comment_id) {
    return apiClient.delete('/dzialki/api/commentaire-parcelle/' + comment_id)
  },
  updatePlotComment(data) {
    return apiClient.put('/dzialki/api/commentaire-parcelle/' + data.commentaire_parcelle_id + '/', data)
  },
  getPersonneList({offset, limit, search, filter, extra = false} = {}) {
    const projet_id = store.state.project.active_project.projet_id || null
    let query = '/dzialki/api/personne/?search=' + search + "&limit=" + limit + "&offset=" + offset
    query = projet_id && !extra ? query + '&projet_id=' + projet_id : query
    query = filter ? query + '&filter=' + filter : query
    return apiClient.get(query)
  },
  getPersonneDetail(id) {
    return apiClient.get('/dzialki/api/personne/' + id)
  },
  deletePersonne(id) {
    return apiClient.delete('/dzialki/api/personne/' + id)
  },
  createPersonnePhysique(data) {
    return apiClient.post('/dzialki/api/personne-physique/', data)
  },
  updatePersonnePhysique(data) {
    return apiClient.put('/dzialki/api/personne-physique/' + data.personne_physique_id + '/', data)
  },

  download(url){
    // return apiClient.get(url)
    return apiClient.get(url, {responseType: 'blob'})
    // return apiClient.get(url, {responseType: 'blob', headers:{'Content-Disposition': 'attachment'}})
  },

  createPersonneMorale(data) {
    return apiClient.post('/dzialki/api/personne-morale/', data)
  },

  updatePersonneMorale(data) {
    return apiClient.put('/dzialki/api/personne-morale/' + data.personne_morale_id + '/', data)
  },
  getItem(item_id, endpoint){
    return apiClient.get('/dzialki/api/' + endpoint + '/' + item_id)
  },
  updateItem(item_id, data, endpoint) {
    return apiClient.put('/dzialki/api/' + endpoint + '/' + item_id + '/', data)
  },
  patchItem(item_id, data, endpoint) {
    return apiClient.patch('/dzialki/api/' + endpoint + '/' + item_id + '/', data)
  },
  createItem(data, endpoint) {
    if (!data.url_fichier instanceof File) {
      return apiClient.post('/dzialki/api/' + endpoint + '/', data)
    } else {
      for (const [key, value] of Object.entries(data)) {
        if (value == null) {data[key] = ""}
      }
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]))
      return apiClient.post('/dzialki/api/' + endpoint + '/', formData, {headers: {'Content-Type': 'application/json'}})
    }

  },
  deleteItem(item_id, endpoint) {
    return apiClient.delete('/dzialki/api/' + endpoint + '/' + item_id)
  },
  getItemList(endpoint){
    return apiClient.get('/dzialki/api/' + endpoint + '/')
  },
  getTypeDroitList() {
    return apiClient.get('/dzialki/api/type-droit/')
  },
  getTypeFichierList(filter) {
    let query = '/dzialki/api/type-fichier/'
    query = filter ? query + '?filter=' + filter : query
    return apiClient.get(query)
  },
  deleteTypeDroit(old_droit, new_droit) {
    return apiClient.delete('/dzialki/api/type-droit/' + old_droit + '/?new_droit=' + new_droit)
  },
  getStatutParcelleList() {
    return apiClient.get('/dzialki/api/statut-parcelle/')
  },
  deleteStatutParcelle(old_statut, new_statut){
    return apiClient.delete('/dzialki/api/statut-parcelle/' + old_statut + '/?new_statut=' + new_statut)
  },
  deleteTypeFichier(old_type, new_type){
    return apiClient.delete('/dzialki/api/type-fichier/' + old_type + '/?new_type=' + new_type)
  },
  getAgreementList({offset, limit, search, filter, extra = false} = {}) {
    const projet_id = store.state.project.active_project.projet_id || null
    let query = '/dzialki/api/contrat/?search=' + search + "&limit=" + limit + "&offset=" + offset
    query = projet_id && !extra ? query + '&projet_id=' + projet_id : query
    query = filter ? query + '&filter=' + filter : query
    return apiClient.get(query)
  },
  getAgreementDetail(id) {
    return apiClient.get('/dzialki/api/contrat/' + id)
  },
  downloadCadastre(insee){
    const projet_id = store.state.project.active_project.projet_id
    const data = {code_com: insee, project_id: projet_id}
    return apiClient.post('/dzialki/api/cadastre/', data)
  },
  uploadDroitFile(data){
    const projet_id = store.state.project.active_project.projet_id
    data = {...data, project_id: projet_id}
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]))
    return apiClient.post('/dzialki/api/upload-droit-file/', formData, {headers: {'Content-Type': 'application/json'}})
  },



}