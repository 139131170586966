<template>
  <v-card class="mx-auto" max-width="1000">
    <v-data-table
        :headers="headers"
        :items="this.c.personnes"
        disable-pagination
        hide-default-footer
        sort-by="personne.nom_complet"
        class="elevation-2"
        no-data-text="Aucune personne"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Signataires</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="justify-center">Voulez-vous supprimer cet ayant-droit ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeItem">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEdit" persistent max-width="500px">
            <template v-slot:activator="{on: dialog}">
              <v-tooltip left>
                <template v-slot:activator="{on: tooltip}">
                  <v-btn icon v-on="{...dialog, ...tooltip}">
                    <v-icon large color="blue-grey">mdi-account-plus</v-icon>
                  </v-btn>
                </template>
                <span>Ajouter une personne à ce contrat</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-form
                  ref="form"
                  v-model="form_valid"
                  @submit.prevent="saveItem"
              >
                <v-card-title class="justify-center">
                  <span class="headline">Ajouter/Modifier une personne</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <BasePersonneSelect
                          :value="editedItem.personne"
                          label="Personne"
                          @input="(data) => {editedItem.personne = data; editedItem.personne_id = data.personne_id}"
                          :rules="[vr.personne_required, vr.personne_unique(c.personnes, editedItem)]"
                      ></BasePersonneSelect>
                    </v-row>
                    <v-row>
                      <v-spacer/>
                      <error-manager :serverErrors="serverErrors"/>
                      <v-spacer/>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-grey" text @click="closeItem">Annuler</v-btn>
                  <v-btn type='submit' :disabled="!form_valid" color="blue-grey" text>Sauvegarder
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.personne.type="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :to="{name: item.personne.type === 'pp' ? 'personne-physique': 'personne-morale', params: {id: item.personne.personne_id}}">
              <v-icon small v-bind="attrs" v-on="on">{{ item.personne.type === 'pp' ? 'mdi-account' : 'mdi-briefcase' }}</v-icon>
            </v-btn>
          </template>
          <span>Plus d'info sur cette personne</span>
        </v-tooltip>
      </template>
      <template v-slot:item.personne.fav_adr="{ item }">
        <span>{{ item.personne.fav_adr.ligne_1 }}</span><br v-if="item.personne.fav_adr.ligne_1">
        <span>{{ item.personne.fav_adr.ligne_2 }}</span><br v-if="item.personne.fav_adr.ligne_2">
        <span>{{ item.personne.fav_adr.code_postal }} {{ item.personne.fav_adr.commune }}</span>
        <span v-if="item.personne.fav_adr.pays !== 'France'"> {{ item.personne.fav_adr.pays }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ErrorManager from "@/components/ErrorManager";
export default {
  name: "TablePersonnes",
  components: {'error-manager': ErrorManager},
  data() {
    return {
      headers: [
        {text: 'Type', value: 'personne.type', align: 'center', sortable: false},
        {text: 'Nom complet', value: 'personne.nom_complet', align: 'start', sortable: false},
        {text: 'Téléphone', value: 'personne.fav_tel', sortable: false},
        {text: 'E-mail', value: 'personne.fav_mail', sortable: false},
        {text: 'Adresse', value: 'personne.fav_adr', sortable: false},
        {text: 'Supprimer', value: 'actions', sortable: false},
      ],
      editedItem: {},
      defaultItem: {},
      dialogDelete: false,
      dialogEdit: false,
      form_valid: true,
      serverErrors: {},
    }
  },
  computed: {
    ...mapState({
      c: state => state.c.c,
    }),
  },
  methods: {
    ...mapActions('c', [
      'delete_item',
      'update_item',
      'add_item'
    ]),
    closeItem() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.$refs.form && this.$refs.form.resetValidation()
      this.serverErrors = {}
      this.dialogEdit = false
      this.dialogDelete = false
    },
    editItem: function (item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    addItem: function () {
      this.dialogEdit = true
    },
    saveItem() {
      this.editedItem.contrat = this.c.contrat_id
      if (this.editedItem["contratpersonne_id"]) {
        this.update_item({
          "array": this.c.personnes,
          "item": this.editedItem,
          "idKey": "contratpersonne_id",
          "endpoint": "contrat-personne"
        }).then((response) => {
          this.closeItem()
        }).catch(error => {
          if (!!error.response && error.response.status === 400) {
            this.serverErrors = error.response.data
          }
        })
      } else {
        this.add_item({
          "item": this.editedItem,
          "array": this.c.personnes,
          "endpoint": "contrat-personne"
        }).then((response) => {
          this.closeItem()
        }).catch(error => {
          if (!!error.response && error.response.status === 400) {
            this.serverErrors = error.response.data
          }
        })
      }
    },
    deleteItem: function (item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.delete_item({
        "item": this.editedItem,
        "array": this.c.personnes,
        "idKey": "contratpersonne_id",
        "endpoint": "contrat-personne"
      }).then((response) => {
        this.closeItem()
      })
    },
  },
}
</script>

<style scoped>

</style>