<template>
  <v-app>
      <NavBar :name="$route.name" class="pb-10" ></NavBar>
    <v-main>
      <v-container>
        <keep-alive :include="['Parcelles', 'Personnes', 'Contrats']">
          <router-view :key="$route.fullPath"/>
        </keep-alive>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    NavBar
  },

  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav)
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("beforeunload", this.preventNav)
  //   });
  // },
  //
  // methods: {
  //   preventNav(event) {
  //     event.preventDefault()
  //     event.returnValue = ""
  //   }
  // }
}
</script>
<style>
  a { text-decoration: none; }
</style>