<template>
  <v-select
      :items="type_droit_list"
      :value="value"
      @input="$emit('input', $event)"
      item-text='description'
      item-value="description"
      :label="label"
      :hint="hint"
      persistent-hint
      :rules='rules || [vr.required]'
      :menu-props="{offsetY: true}"
  >
    <template v-if="!noteditable" v-slot:append-outer>
      <TableTypeDroits></TableTypeDroits>
    </template>
  </v-select>
</template>

<script>
import TableTypeDroits from "@/components/TableTypeDroits";
import {mapActions, mapState} from "vuex";
export default {
  name: "BaseTypeDroitSelect",
  components: {TableTypeDroits},
  props: {
    "value": String,
    "label": String,
    "hint": String,
    'rules': Array,
    'noteditable': {
      'type': Boolean,
      'default': () => false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      type_droit_list: state => state.droit.type_droit_list
    }),
  },
  created() {
    this.get_type_droit_list()
  },
  methods:{
    ...mapActions('droit', [
      'get_type_droit_list'
    ]),
  }
}
</script>

<style scoped>

</style>