<template>
  <v-card class="mx-auto" max-width="1000">
    <v-data-table
        :headers="droits_headers"
        :items="this.p.droits"
        :items-per-page="10"
        sort-by="parcelle.iduu"
        class="elevation-2"
        no-data-text="Aucun droit"
    >
      <template v-slot:item.parcelle.iduu="{item}">
        {{ item.parcelle.iduu }}
        <v-btn icon :to="{ name: 'parcelle', params: { id: item.parcelle_id }}">
          <v-icon small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.parts="{ item }">
        {{ item.numerateur + '/' + item.denominateur }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem (item)">mdi-delete</v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Droits fonciers</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogEdit" persistent max-width="400px">
            <template v-slot:activator="{on: dialog}">
              <v-tooltip left>
                <template v-slot:activator="{on: tooltip}">
                  <v-btn icon v-on="{...dialog, ...tooltip}">
                    <v-icon large color="blue-grey">mdi-file-document-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Ajouter un droit</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title class="justify-center">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                      ref="droit_form"
                      v-model="droit_form_valid"
                      @submit.prevent="save"
                  >
                    <v-row>
                      <v-col cols="12">
                        <BaseParcelleSelect
                            :value="editedItem.parcelle"
                            label="Parcelle (iduu)"
                            @input="(data) => {editedItem.parcelle = data; editedItem.parcelle_id = data.parcelle_id}"
                        ></BaseParcelleSelect>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <BaseTypeDroitSelect
                            v-model="editedItem.type_droit"
                            label="Type de Droit"
                        ></BaseTypeDroitSelect>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field :error-messages='check_ratio' ref="numerateur" name="numerateur"
                                      :rules='[vr.required, vr.entier_positif]' type="number"
                                      v-model.number="editedItem.numerateur" label="Numérateur"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field :error-messages='check_ratio' name="denominateur"
                                      :rules='[vr.required, vr.entier_positif]' type="number"
                                      v-model.number="editedItem.denominateur" label="Dénominateur"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-grey" text @click="close">Annuler</v-btn>
                <v-btn type='submit' :disabled="!droit_form_valid || form_unmodified" color="blue-grey" text
                       @click="save">Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" persistent max-width="400px">
            <v-card>
              <v-card-title class="justify-center">Voulez-vous supprimer ce droit ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import CPMSService from "@/services/CPMSService"
import BaseParcelleSelect from "@/components/form_elements/BaseParcelleSelect";

export default {
  name: "TableDroits",
  components: {BaseParcelleSelect},
  props: ['loading'],
  data: function () {
    return {
      droit_form_valid: false,
      dialogEdit: false,
      dialogDelete: false,
      droits_headers: [
        {text: 'IDUU', value: 'parcelle.iduu', align: "center"},
        {text: 'Commune', value: 'parcelle.nom_com'},
        {text: 'Section', value: 'parcelle.section'},
        {text: 'Numéro', value: 'parcelle.numero'},
        {text: 'Type de droit', value: 'type_droit'},
        {text: 'Parts', value: 'parts', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      editedItem: {},
      defaultItem: {},
    }
  },
  computed: {
    form_unmodified() {
      const originalItem = this.editedItem["droit_id"] ? this.p.droits.find(element => element["droit_id"] === this.editedItem["droit_id"]) : {}
      for (let key in this.editedItem) {
        if (this.editedItem[key] !== originalItem[key]) {
          return false
        }
      }
      return true

    },
    formTitle() {
      return this.editedItem["droit_id"] ? 'Modifier Droit' : 'Créer un nouveau droit'
    },
    ...mapState({
      p: state => state.p.p
    }),
    check_ratio() {
      const ratio = this.editedItem.numerateur / this.editedItem.denominateur
      if (ratio > 1) {
        return "Le numérateur ne peut être supérieur au dénominateur"
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('p', [
      'delete_item',
      'update_item',
      'add_item'
    ]),
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.delete_item({
        "item": this.editedItem,
        "array": this.p.droits,
        "idKey": "droit_id",
        "endpoint": "droit"
      }).then(() => {
        this.close()
      })
    },
    save() {
      if (this.editedItem["droit_id"]) {
        this.update_item({
          "array": this.p.droits,
          "item": this.editedItem,
          "idKey": "droit_id",
          "endpoint": "droit"
        }).then(() => {
          this.close()
        })
      } else {
        this.editedItem.personne = this.editedItem.personne_id = this.p.personne_id
        this.add_item({
          "item": this.editedItem,
          "array": this.p.droits,
          "endpoint": "droit"
        }).then(() => (this.close()))
      }
    },
    close() {
      this.dialogEdit = false
      this.dialogDelete = false
      !!this.$refs.droit_form && this.$refs.droit_form.resetValidation()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
  },
}
</script>

<style scoped>

</style>