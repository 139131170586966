<template>
  <div id="nav">
    <v-app-bar app color="blue-grey" dark>
      <a v-if="LoggedIn" :href="$router.resolve({name: 'projets'}).href">
        <v-icon>mdi-home</v-icon>
      </a>
      <v-btn icon v-if="LoggedIn" @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon v-if="LoggedIn" @click="$router.go(1)">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn icon v-if="LoggedIn" @click="increment_component_key">
        <v-icon>mdi-reload</v-icon>
      </v-btn>

      <v-toolbar-title class="text-left text-h4 pl-10">{{ page }}</v-toolbar-title>
      <v-spacer/>
      <v-menu offset-y v-if="isActiveProject">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text>
            {{ active_project.nom || 'Sélectionner un projet' }}
            <v-icon class="pl-2">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="projet in project_list" :key="projet.projet_id" @click="selectProject(projet)">
            <v-list-item-title>{{ projet.nom }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span v-if="LoggedIn" class="px-5">Bonjour {{ user.first_name || user.username }}</span>
      <v-tooltip v-if="LoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="logout" v-bind="attrs" v-on="on" icon>
            <v-icon>
              mdi-logout
            </v-icon>
          </v-btn>
        </template>
        <span>Se déconnecter</span>
      </v-tooltip>
      <template v-if="LoggedIn && isActiveProject" v-slot:extension>
        <v-tabs align-with-title>
          <v-tab :to='{name: "personnes"}'>Personnes</v-tab>
          <v-tab :to='{name: "parcelles"}'>Parcelles</v-tab>
          <v-tab :to='{name: "contrats"}'>Contrats</v-tab>
          <v-tab>Projet</v-tab>
        </v-tabs>
<!--        <v-btn text :to="{name: 'personnes'}">Personnes</v-btn>-->
<!--        <v-btn text :to="{name: 'parcelles'}">Parcelles</v-btn>-->
<!--        <v-btn text :to="{name: 'contrats'}">Contrats</v-btn>-->
<!--        <v-btn text>Projet</v-btn>-->
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
export default {
  name: "NavBar",
  data: function () {
    return {}
  },
  computed: {
    ...mapState({
      page: state => state.page,
      user: state => state.user.user,
      project_list: state => state.project.project_list,
      active_project: state => state.project.active_project,
      componentKey: state => state.componentKey
    }),
    ...mapGetters('user', [
      'LoggedIn',
    ]),
    ...mapGetters('project', [
      'isActiveProject'
    ])
  },
  methods: {
    ...mapActions([
      'increment_component_key',
    ]),
    ...mapActions('project', [
      'set_active_project',
      'get_project_list'
    ]),
    ...mapActions('user', [
      'logout',
    ]),
    selectProject(project) {
      this.set_active_project(project)
      if (this.$route.meta.parent !== this.$route.name) {
        this.$router.push({
          name: this.$route.meta.parent
        })
      }
    }
  },
  created() {
    if (this.project_list.length === 0 && this.LoggedIn){this.get_project_list()}
  },

}
</script>

<style scoped>

</style>