<template>
  <div>
    <v-card class="mx-auto mb-10" max-width="550">
      <v-list>
        <v-card-title class="font-weight-bold text-h5">
          <v-spacer/>
          {{ c.type }} : {{ c.numero }}
          <v-spacer/>
          <Cform v-slot="{editItem, deleteItem, addItem}">
              <v-tooltip left>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" icon @click.stop="addItem()">
                    <v-icon color="blue-grey">mdi-book-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>Créer un Contrat</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" icon @click.stop="editItem(c)">
                    <v-icon color="blue-grey">mdi-book-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Créer/Modifier Contrat</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" icon @click.stop="deleteItem(c)">
                    <v-icon color="blue-grey">mdi-book-remove-outline</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer ce contrat</span>
              </v-tooltip>
          </Cform>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <component :is="contratComponent" :c="c"/>
        </v-card-text>
      </v-list>
    </v-card>
    <TableParcelles/>
    <br/>
    <TablePersonnes/>
    <br/>
    <table-fichiers parent="Contrat"></table-fichiers>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import TableParcelles from "@/components/TableParcelles"
import TablePersonnes from "@/components/TablePersonnes"
import TableFichiers from "@/components/TableFichiers"
import Cform from "@/components/Cform"

export default {
  components: {TablePersonnes, TableParcelles, Cform, TableFichiers},
  props: ['id'],
  name: "Contrat",
  data: function () {
    return {
      titre_page: 'Détail Contrat',
    }
  },
  created() {
    this.get_c(this.id).then(() => {
      this.update_page(this.titre_page + ': ' + this.c.numero)
    })
  },
  methods: {
    ...mapActions([
      'update_page',
    ]),
    ...mapActions('c', [
      'get_c',
    ]),
  },
  computed: {
    ...mapState({
      c: state => state.c.c,
      componentKey: state => state.componentKey
    }),
    contratComponent() {
      if (this.c.type === 'Promesse de Bail') return () => import(/* webpackChunkName: "PromesseDeBail" */ "@/components/contrats/cartes/PromesseDeBail_carte")
      if (this.c.type === 'Convention de Servitude') return () => import(/* webpackChunkName: "ConventionDeServitude" */ "@/components/contrats/cartes/ConventionDeServitude_carte")
      if (this.c.type === 'Bail') return () => import(/* webpackChunkName: "Bail" */ "@/components/contrats/cartes/Bail_carte")
      if (this.c.type === 'Mât de Mesure') return () => import(/* webpackChunkName: "MatDeMesure" */ "@/components/contrats/cartes/MatDeMesure_carte")
      else return () => import(/* webpackChunkName: "DefaultTemplate" */ "@/components/contrats/cartes/DefaultTemplate_carte")
    },
  },
  watch: {
    componentKey() {
      this.get_c(this.id)
    }
  }
}
</script>

<style scoped>

</style>