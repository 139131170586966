<template>
  <div>
    <slot :opendialog="opendialog"></slot>
    <v-dialog v-model="dialog" max-width="550px" persistent>
      <v-card>
        <v-form
            ref="form"
            v-model="form_valid"
            @submit.prevent="sendfile"
        >
          <v-card-title class="justify-center">
            <span class="headline">Importer des droits depuis un fichier</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-file-input
                    v-model="fichier"
                    :rules='[vr.required, vr.xlfile]'
                    show-size
                    label="Entrez un fichier Excel"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="inprogress">
              <v-progress-linear
                  indeterminate
                  color="blue darken-1"
              ></v-progress-linear>
            </v-row>
            <v-row class="d-flex justify-center" v-if="inprogress">
              Données en cours de chargement, veuillez patienter
            </v-row>
          </v-card-text>
          <v-card-actions v-if="!inprogress">
            <v-spacer></v-spacer>
            <v-btn color="blue-grey" text @click="closedialog">Annuler</v-btn>
            <v-btn type='submit' :disabled="!form_valid" color="blue-grey" text>Envoyer</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ImportDroits",
  data() {
    return {
      inprogress: false,
      dialog: false,
      fichier: null,
      form_valid: false,
    }
  },
  methods: {
    ...mapActions('droit', [
      'upload_file'
    ]),
    opendialog() {
      this.dialog = true
    },
    closedialog() {
      this.dialog = false
      !!this.$refs.form && this.$refs.form.resetValidation()
      this.fichier = null
    },
    sendfile() {
      this.inprogress = true
      this.upload_file({"fichier": this.fichier}).then((response) => {
        console.log(response.data)
        // this.closedialog()
        this.inprogress = false
      })
    },
  }
}
</script>

<style scoped>

</style>