<template>
  <v-select
      :items="types_fichiers_list"
      :value="value"
      @input="$emit('input', $event)"
      :menu-props="{offsetY: true}"
      label="Type de pièce jointe"
      item-text="nom_type"
      return-object
      :rules=[vr.required]
  >
    <template v-slot:item="{ item, on : select, attrs }">
      <v-list-item v-bind="attrs" v-on="select">
        <v-list-item-content class="my-0 py-0">
          {{ item.nom_type }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:prepend>
      <TableTypesFichiers
          :referent="referent"
          @update_your_data_bro="$emit('update_your_data_bro', $event)"
      />
    </template>
  </v-select>
</template>

<script>
import CPMSService from "@/services/CPMSService";
import TableTypesFichiers from "@/components/TableTypesFichiers";
import {mapActions, mapState} from "vuex";

export default {
  name: "BaseTypeFichierSelect",
  components: {TableTypesFichiers},
  props: ["referent", "value"],
  data() {
    return {}
  },
  created() {
    this.get_types_fichiers_list({referent: this.referent})
  },
  methods: {
    ...mapActions('types_fichiers', [
      'get_types_fichiers_list',])
  },
  computed: {
    ...mapState({
      types_fichiers_list: state => state.types_fichiers.types_fichiers_list,
      componentKey: state => state.componentKey
    }),
  }
}
</script>

<style scoped>

</style>