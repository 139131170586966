import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import {validation_rules} from "@/mixins/ValidationMixin";
Vue.config.productionTip = false

const requireComponent = require.context('./components', true, /Base[A-Z]\w+\.(vue|js)$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')))
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.mixin(validation_rules)

new Vue({
  router,
  store,
  vuetify,
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.dispatch('user/set_user_data', userData)
    }
    const active_project_string = localStorage.getItem('active_project')
    if (active_project_string) {
      const active_project = JSON.parse(active_project_string)
      this.$store.dispatch('project/set_active_project', active_project)
    }
  },
  render: h => h(App)
}).$mount('#app')
