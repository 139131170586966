<template>
  <v-text-field
      :name="this._uid"
      :rules="!!rules ? [vr.date_valid, ...rules]: [vr.date_valid]"
      :value="ISOtoUSR(value)"
      @input="parseDate"
      :disabled="activator != null && !activator"
      :label="label"
      validate-on-blur
      hint="JJ/MM/AAAA"
  >
    <template v-if="activator != null" v-slot:prepend>
      <v-btn class="lock-button" icon @click="enable">
        <v-icon>{{
            !!activator ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
          }}
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:append>
      <v-menu
          ref="menu"
          v-model="menu"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
        </template>
        <v-date-picker
            :value="value"
            no-title
            scrollable
            @input="formatDate"
        >
        </v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
import {format_functions} from "@/mixins/UtilityMixin";
import moment from "moment";
export default {
  name: "BaseDateSelect",
  props: ["value", "label", "refs", "activator", "rules"],
  mixins: [format_functions],
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    formatDate(date) {
      this.$emit('input', date)
      this.$refs.menu.save() // sert à fermer le date picker
      this.refs.form.inputs.find(el => el.$attrs.name === this._uid).resetValidation()
    },
    parseDate(date) {
      const mom = this.parse_date_fromUSR(date)
      if (!date) {
        this.$emit('input', null)
      }
      else if (mom.isValid()) {
        this.$emit('input', this.format_date_toISO(mom))
      }
    },
    enable() {
      this.$emit('enabled', !this.activator)
      if (!this.activator === false) this.$emit('input', null)
    },
  },
}
</script>

<style scoped>
.lock-button {
  pointer-events: auto;
}
</style>