<template>
  <div>
    <div class="text-h6 pt-5">
      {{ metaData.titre }}
      <v-btn icon @click="dialogEdit=true">
        <v-icon color="blue-grey"> mdi-plus-circle</v-icon>
      </v-btn>
      <v-dialog v-model="dialogEdit" persistent max-width="450px">
        <v-card>
          <v-form
              ref="item_form"
              v-model="item_form_valid"
              @submit.prevent="saveItem"
          >
            <v-card-title class="justify-center">{{ formTitle }}</v-card-title>
            <v-card-text>
              <slot name="formtemplate" :editedItem="editedItem" :rules="rules"></slot>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
              <v-btn type='submit' color="blue darken-1" text :disabled="!item_form_valid || form_unmodified">
                Sauvegarder
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" persistent max-width="450px">
        <v-card>
          <v-card-title class="justify-center">{{ this.metaData.delMessage }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row v-if="itemArray.length > 0">
      <v-col v-if=!metaData.groupedCards
             v-for="item in itemArray"
             :key="item[metaData.editedIdKey]"
             cols="12" sm="6" md="6">
        <v-card>
          <v-card-text class="pa-2 pb-1 text-body-1"
                       :class="{'font-weight-bold': item.prefere && itemArray.length > 1}">
            <slot name="template" :item="item"></slot>
          </v-card-text>
          <v-card-actions class="pa-2 pt-1 justify-end">
            <v-icon color="blue-grey" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon color="blue-grey" small class="mr-2" @click="deleteItem(item, itemArray)">mdi-delete</v-icon>
            <v-icon color="blue-grey" small @click="star(item)" v-show="item.prefere">mdi-star</v-icon>
            <v-icon color="blue-grey" small @click="star(item)" v-show="!item.prefere">mdi-star-outline</v-icon>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if=metaData.groupedCards cols="12" sm="6" md="6">
        <v-card class="pa-0">
          <v-card-text class="pa-2 pb-1">
            <v-list dense class="pa-0">
              <v-list-item v-for="item in itemArray"
                           :key="item[metaData.editedIdKey]"
                           class="pa-0"
              >
                <v-list-item-content
                    :class="{'font-weight-bold': item.prefere && itemArray.length > 1, 'pa-0': true}"
                    class="pa-0 text-body-1">
                  <slot name="template" :item="item"></slot>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="blue-grey" small @click="editItem(item)" class="pa-1">mdi-pencil</v-icon>
                  <v-icon color="blue-grey" small @click="deleteItem(item)" class="pa-1">mdi-delete</v-icon>
                  <v-icon color="blue-grey" small @click="star(item)" v-show="item.prefere" class="pa-1">mdi-star
                  </v-icon>
                  <v-icon color="blue-grey" small @click="star(item)" v-show="!item.prefere" class="pa-1">
                    mdi-star-outline
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: "BlocItem",
  props: ["itemArray", "metaData"],

  data: function () {
    return {
      item_form_valid: false,
      dialogDelete: false,
      dialogEdit: false,
      defaultItem: {prefere: false, pays: "France", indicatif: "+33", personne: {}},
      editedItem: {prefere: false, pays: "France", indicatif: "+33", personne: {}},
    }
  },
  computed: {
    ...mapState({
      p: state => state.p.p
    }),
    rules() {
      return {
        required: this.vr.required,
        email: this.vr.email
      }
    },
    form_unmodified() {
      const originalItem = this.editedItem[this.metaData.editedIdKey] ? this.itemArray.find(element => element[this.metaData.editedIdKey] === this.editedItem[this.metaData.editedIdKey]) : {}
      for (let key in this.editedItem) {
        if (this.editedItem[key] !== originalItem[key]) {
          return false
        }
      }
      return true
    },
    formTitle() {
      return this.editedItem[this.metaData.editedIdKey] ? this.metaData.editMessage : this.metaData.createMessage
    },
  },
  methods: {
    ...mapActions('p', [
      'delete_item',
      'add_item',
      'update_item',
      'update_star'
    ]),
    star: function (item) {
      this.update_star({
        "idKey": this.metaData.editedIdKey,
        "endpoint": this.metaData.endpoint,
        "array": this.itemArray,
        "item": item
      })
    },
    deleteItem: function (item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    close: function () {
      this.dialogDelete = false
      this.dialogEdit = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.$refs.item_form && this.$refs.item_form.resetValidation()
    },
    deleteItemConfirm: function () {
      this.delete_item({
        "item": this.editedItem,
        "array": this.itemArray,
        "idKey": this.metaData.editedIdKey,
        "endpoint": this.metaData.endpoint
      }).then(() => {
        this.close()
      })
    },
    editItem: function (item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    saveItem() {
      if (this.editedItem[this.metaData.editedIdKey]) {
        this.update_item({
          "array": this.itemArray,
          "item": this.editedItem,
          "idKey": this.metaData.editedIdKey,
          "endpoint": this.metaData.endpoint,
        }).then(() => {
          this.close()
        })
      } else {
        if (this.metaData.endpoint === "contact") {
          this.editedItem.personne_morale = this.p.personne_morale.personne_morale_id
        } else { //rajouter adress, telephone, email à une personne
          this.editedItem.personne = this.editedItem.personne_id = this.p.personne_id
        }
        this.add_item({
          "item": this.editedItem,
          "array": this.itemArray,
          "endpoint": this.metaData.endpoint
        }).then(() => (this.close()))
      }
    },
  },
}
</script>

<style scoped>

</style>