<template>
  <span>
    <slot :deleteItem="deleteItem" :editItem="editItem" :newItem="newItem" name="boutons"></slot>
    <v-dialog v-model="dialogEdit" max-width="650px" persistent>
      <v-card>
        <v-form
            ref="form"
            v-model="form_valid"
            lazy-validation
            @submit.prevent="saveItem"
        >
          <v-card-title class="justify-center">{{ editMessage }}</v-card-title>
          <v-card-text>
            <v-container v-if="this.ptype === 'pp'">
              <v-row>
                <v-col cols="2">
                  <v-select
                      :items="civilites"
                      label="Civilité"
                      :menu-props="{offsetY: true}"
                      v-model="editedItem.civilite"
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-text-field :rules=[vr.required] v-model="editedItem.nom_famille" label="Nom de famille"
                                type="text"></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field v-model="editedItem.nom_usage" label="Nom d'usage" type="text"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules=[vr.required] v-model="editedItem.prenom" label="Prénom"
                                type="text"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="editedItem.deuxiemes_prenoms"
                      label="Deuxième prénoms"
                      type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <BaseDateSelect
                      v-model="editedItem.date_naissance"
                      label="Date de naissance"
                      :refs="this.$refs"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="editedItem.lieu_naissance"
                      label="Lieu de naissance"
                      type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                    <BaseDateSelect
                        v-model="editedItem.date_deces"
                        :activator="editedItem.decede"
                        @enabled="(data) => {editedItem.decede = data}"
                        label="Date de décès"
                        :refs="this.$refs"
                    />
                </v-col>
                <v-spacer/>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea v-model="editedItem.remarques" label="Remarques" type="text"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="this.ptype === 'pm'">
              <v-row>
                <v-col>
                  <v-text-field :rules=[vr.required] type="text" v-model="editedItem.nom" label="Nom"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field type="text" v-model="editedItem.forme_juridique"
                                label="Forme juridique"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field type="text" v-model="editedItem.siren" label="SIREN"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field type="text" v-model="editedItem.rcs"
                                label="RCS"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea type="text" v-model="editedItem.remarques" label="Remarques"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
            <v-btn type='submit' :disabled="!form_valid || form_unmodified" color="blue darken-1"
                   text>Sauvegarder</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="450px">
        <v-card>
          <v-card-title class="justify-center">Voulez vous supprimer cette personne ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </span>
</template>

<script>
import {mapActions, mapState} from "vuex"
import moment from "moment"

export default {
  name: "Pform",
  props: ['ptype'],
  data: function () {
    return {
      civilites: ['M.', 'Mme.'],
      form_valid: true,
      dialogEdit: false,
      dialogDelete: false,
      editedItem: {},
    }
  },
  methods: {
    ...mapActions('p', [
      'update_pp',
      'create_pp',
      'delete_p',
      'update_pm',
      'create_pm',
      'delete_pm',
    ]),
    editItem: function () {
      if (this.ptype === 'pp') {
        this.editedItem = Object.assign({}, this.p.personne_physique)
      } else if (this.ptype === 'pm') {
        this.editedItem = Object.assign({}, this.p.personne_morale)
      }
      this.dialogEdit = true

    },
    newItem: function () {
      this.dialogEdit = true
    },
    deleteItem: function (item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm: function () {
      this.delete_p(this.editedItem)
          .then(() => {
            this.close()
            if (this.$route.name !== 'personnes') {
              this.$router.push({
                name: 'personnes',
              })
            }
          })
    },
    close: function () {
      this.dialogEdit = false
      this.dialogDelete = false
      this.editedItem = {}
      this.$refs.form && this.$refs.form.resetValidation()
    },
    saveItem() {
      if (this.$refs.form.validate()) {
        if (this.ptype === 'pp' && this.editedItem.personne_physique_id) {
          this.update_pp(this.editedItem).then(() => {
            this.close()
          })
        } else if (this.ptype === 'pp') {
          this.create_pp(this.editedItem).then((response) => {
            this.close()
            this.$router.push({
              name: 'personne-physique',
              params: {id: this.p.personne_physique.personne_id}
            })
          })
        } else if (this.ptype === 'pm' && this.editedItem.personne_morale_id) {
          this.update_pm(this.editedItem).then(() => {
            this.close()
          })
        } else if (this.ptype === 'pm') {
          this.create_pm(this.editedItem).then((response) => {
            this.close()
            this.$router.push({
              name: 'personne-morale',
              params: {id: this.p.personne_morale.personne_id}
            })
          })
        }
      }
    },
  },
  computed: {
    ...mapState({
      p: state => state.p.p
    }),
    editMessage() {
      if (this.ptype === 'pp' && this.editedItem.personne_physique_id) {
        return "Modifier les données personnelles"
      } else if (this.ptype === 'pp') {
        return "Créer une nouvelle Personne Physique"
      } else if (this.ptype === 'pm' && this.editedItem.personne_morale_id) {
        return "Modifier les coordonnées"
      } else if (this.ptype) {
        return "Créer une nouvelle Personne Morale"
      }
    },
    form_unmodified() {
      for (let key in this.editedItem) {
        if (this.editedItem[key] !== this.p[key]) {
          return false
        }
      }
      return true
    },
  }
}
</script>

<style scoped>
.lock-button {
  pointer-events: auto;
}
</style>