var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1200"}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.c_list,"search":_vm.search,"server-items-length":_vm.c_list_count,"options":_vm.options,"item-key":"contrat_id","loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":{'items-per-page-options': [10, 30, 100],}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un contrat"},on:{"input":_vm.va_chercher},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"pl-10",attrs:{"label":"Recherche élargie"},model:{value:(_vm.extra_data),callback:function ($$v) {_vm.extra_data=$$v},expression:"extra_data"}})],1)]}}])},[_c('span',[_vm._v("Affiche tous les contrats de la base de données"),_c('br'),_vm._v("plutôt que ceux du projet uniquement")])]),_c('v-spacer'),_c('Cform',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var addItem = ref.addItem;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return addItem()}}},on),[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-book-plus-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Créer un Contrat")])])]}}])})],1)]},proxy:true},{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._v(" "+_vm._s(item.numero)+" "),_c('v-btn',{attrs:{"icon":"","to":{name: 'contrat', params: {id: item.contrat_id}}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-book-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Détails du contrat")])])]}},{key:"item.date_signature",fn:function(ref){
var item = ref.item;
return [(item.date_signature)?_c('span',[_vm._v(_vm._s(_vm.ISOtoUSR(item.date_signature)))]):_vm._e()]}},{key:"item.parcelles",fn:function(ref){
var item = ref.item;
return _vm._l((item.parcelles),function(parcelle,index){return _c('span',[_vm._v(" "+_vm._s(parcelle.slice(8, 10).replace('0', ''))+" "+_vm._s(parseInt(parcelle.slice(10)))+" "),(index !== Object.keys(item.parcelles).length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})}},{key:"item.personnes",fn:function(ref){
var item = ref.item;
return _vm._l((item.personnes),function(personne,index){return (index+1 <= 3)?_c('div',[_vm._v(" "+_vm._s(personne)+" "),(Object.keys(item.parcelles).length > 3 && index+1 === 3)?_c('span',[_vm._v("...")]):_vm._e()]):_vm._e()})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Cform',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var deleteItem = ref.deleteItem;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return deleteItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer ce contrat")])])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }