<template>
  <v-card class="mx-auto" max-width="1200">
    <v-data-table
        :headers="headers"
        :items="plot_list"
        :search="search"
        :server-items-length="plot_list_count"
        :options.sync="options"
        item-key="parcelle_id"
        class="elevation-1"
        :footer-props="{'items-per-page-options': [10, 30, 100],}"
        :key="componentKey"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher une parcelle"
              @input="va_chercher"
          ></v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch class="pl-10" v-model="extra_data" label="Recherche élargie"/>
              </div>
            </template>
            <span>Affiche les données de la base de données entière <br/>plutôt que celles du projet uniquement</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <ImportDroits v-slot="{opendialog}">
            <v-tooltip left>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" icon @click.stop="opendialog()">
                  <v-icon color="blue-grey">mdi-file-import-outline</v-icon>
                </v-btn>
              </template>
              <span>Importer des droits depuis un fichier</span>
            </v-tooltip>
          </ImportDroits>
          <Plotform v-slot="{addItem}">
            <v-tooltip left>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" icon @click.stop="addItem()">
                  <v-icon color="blue-grey">mdi-map-plus</v-icon>
                </v-btn>
              </template>
              <span>Importer des parcelles</span>
            </v-tooltip>
          </Plotform>
        </v-toolbar>
      </template>
      <template v-slot:item.iduu="{item}">
        {{ item.iduu }}
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :to="{name: 'parcelle', params: {id: item.parcelle_id}}">
              <v-icon small v-bind="attrs" v-on="on">mdi-map-outline</v-icon>
            </v-btn>
          </template>
          <span>Plus d'info sur la parcelle</span>
        </v-tooltip>
      </template>
      <template v-slot:item.supf="{ item }">
        <span v-if="item.supf">{{ item.supf }} m²</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Plotform from "@/components/Plotform"
import ImportDroits from "@/components/ImportDroits"
export default {
  name: "Parcelles",
  components: {Plotform, ImportDroits},
  data() {
    return {
      extra_data: false,
      options: {},
      titre_page: "Liste des parcelles",
      search: '',
      headers: [
        {text: 'IDUU', value: 'iduu', align: "center", sortable: false},
        {text: 'Commune', value: 'nom_com', sortable: false},
        {text: 'Préfixe', value: 'prefixe', sortable: false},
        {text: 'Section', value: 'section', sortable: false},
        {text: 'Numéro', value: 'numero', sortable: false},
        {text: 'Exploitant', value: 'exploitant', sortable: false},
        {text: 'Propriétaire', value: 'proprietaire', sortable: false}
      ],
    }
  },
  watch: {
    all_options: {
      handler() {
        this.get_plot_list({
          "page": this.options.page,
          "itemsPerPage": this.options.itemsPerPage,
          "search": this.search,
          'extra': this.extra_data
        })
      },
      deep: true,
    },
  },
  activated() {
    this.update_page(this.titre_page)
  },
  methods: {
    ...mapActions([
      'update_page',
    ]),
    ...mapActions('plot', [
      'get_plot_list',
    ]),
    goto: function (row) {
      this.$router.push({
        name: 'parcelle',
        params: {id: row.parcelle_id}
      })

    },
    va_chercher: function (input) {
      this.options.page = 1
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.get_plot_list({
          "page": this.options.page,
          "itemsPerPage": this.options.itemsPerPage,
          "search": input,
          'extra': this.extra_data
        })
      }, 500)
    }
  },
  computed: {
    ...mapState({
      plot_list: state => state.plot.plot_list,
      plot_list_count: state => state.plot.plot_list_count,
      componentKey: state => state.componentKey
    }),
    all_options() {
      return {...this.options, "extra_data": this.extra_data}
    }
  }
}
</script>

<style scoped>

</style>


<!--penser à rajouter :key="componentKey" dans la table des parcelles pour qu'elle se mette à jour après un chamngement de projet-->