var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1200"}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.plot_list,"search":_vm.search,"server-items-length":_vm.plot_list_count,"options":_vm.options,"item-key":"parcelle_id","footer-props":{'items-per-page-options': [10, 30, 100],}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher une parcelle"},on:{"input":_vm.va_chercher},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"pl-10",attrs:{"label":"Recherche élargie"},model:{value:(_vm.extra_data),callback:function ($$v) {_vm.extra_data=$$v},expression:"extra_data"}})],1)]}}])},[_c('span',[_vm._v("Affiche les données de la base de données entière "),_c('br'),_vm._v("plutôt que celles du projet uniquement")])]),_c('v-spacer'),_c('ImportDroits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var opendialog = ref.opendialog;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return opendialog()}}},on),[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-file-import-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Importer des droits depuis un fichier")])])]}}])}),_c('Plotform',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var addItem = ref.addItem;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return addItem()}}},on),[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-map-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Importer des parcelles")])])]}}])})],1)]},proxy:true},{key:"item.iduu",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.iduu)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'parcelle', params: {id: item.parcelle_id}}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-map-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Plus d'info sur la parcelle")])])]}},{key:"item.supf",fn:function(ref){
var item = ref.item;
return [(item.supf)?_c('span',[_vm._v(_vm._s(item.supf)+" m²")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }