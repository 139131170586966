var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1000"}},[_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.p_list,"search":_vm.search,"server-items-length":_vm.p_list_count,"options":_vm.options,"item-key":"personne_id","loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":{'items-per-page-options': [10, 30, 100],}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un nom"},on:{"input":_vm.va_chercher},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"pl-10",attrs:{"label":"Recherche élargie"},model:{value:(_vm.extra_data),callback:function ($$v) {_vm.extra_data=$$v},expression:"extra_data"}})],1)]}}])},[_c('span',[_vm._v("Affiche les données de la base de données entière "),_c('br'),_vm._v("plutôt que celles du projet uniquement")])]),_c('v-spacer'),_c('p-form',{attrs:{"ptype":"pp"},scopedSlots:_vm._u([{key:"boutons",fn:function(ref){
var newItem = ref.newItem;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":newItem}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue-grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Nouvelle Personne Physique")])])]}}])}),_c('p-form',{attrs:{"ptype":"pm"},scopedSlots:_vm._u([{key:"boutons",fn:function(ref){
var newItem = ref.newItem;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":newItem}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue-grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-briefcase-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Nouvelle Personne Morale")])])]}}])})],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","to":{name: item.type === 'pp' ? 'personne-physique': 'personne-morale', params: {id: item.personne_id}}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.type === 'pp' ? 'mdi-account' : 'mdi-briefcase'))])],1)]}}],null,true)},[_c('span',[_vm._v("Plus d'info sur cette personne")])])]}},{key:"item.fav_adr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.fav_adr.ligne_1))]),(item.fav_adr.ligne_1)?_c('br'):_vm._e(),_c('span',[_vm._v(_vm._s(item.fav_adr.ligne_2))]),(item.fav_adr.ligne_2)?_c('br'):_vm._e(),_c('span',[_vm._v(_vm._s(item.fav_adr.code_postal)+" "+_vm._s(item.fav_adr.commune))]),(item.fav_adr.pays !== 'France')?_c('span',[_vm._v(" "+_vm._s(item.fav_adr.pays))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('p-form',{scopedSlots:_vm._u([{key:"boutons",fn:function(ref){
var deleteItem = ref.deleteItem;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return deleteItem(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"blue-grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }