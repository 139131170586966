import CPMSService from "@/services/CPMSService";

export const namespaced = true

export const state = {
    'types_fichiers_list': [],
}

export const mutations = {
    UPDATE_TYPES_FICHIERS_LIST(state, data) {
        state.types_fichiers_list = data
    },
    DELETE_TYPE_FICHIER(state, index) {
        state.types_fichiers_list.splice(index, 1)
    },
    UPDATE_TYPE_FICHIER(state, {index, new_type}) {
        state.types_fichiers_list.splice(index, 1, new_type)
    },
    ADD_TYPE_FICHIER(state, new_type) {
        state.types_fichiers_list.push(new_type)
    }
}

export const actions = {
    get_types_fichiers_list({commit}, {referent}) {
        CPMSService.getTypeFichierList(referent)
            .then((response) => {
                commit('UPDATE_TYPES_FICHIERS_LIST', response.data)
            }).catch((error) => {
            console.log(error)
        })

    },

    delete_type_fichier({commit, dispatch, rootState}, {to_delete, to_replace_with}) {
        return new Promise((resolve, reject) => {
            CPMSService.deleteTypeFichier(to_delete.typefichier_id, to_replace_with.typefichier_id)
                .then(() => {
                    const index = state.types_fichiers_list.findIndex(el => el.typefichier_id === to_delete.typefichier_id)
                    const old_name = state.types_fichiers_list[index].nom_type
                    const new_name = to_replace_with.nom_type
                    commit("DELETE_TYPE_FICHIER", index)
                    resolve({old_name: old_name, new_name: new_name})
                }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },

    update_type_fichier({commit, dispatch, rootState}, to_update) {
        return new Promise((resolve, reject) => {
            CPMSService.updateItem(to_update.typefichier_id, to_update, 'type-fichier')
                .then(response => {
                    const index = state.types_fichiers_list.findIndex(el => el.typefichier_id === to_update.typefichier_id)
                    const old_name = state.types_fichiers_list[index].nom_type
                    const new_name = response.data.nom_type
                    commit("UPDATE_TYPE_FICHIER", {index: index, new_type:response.data})
                    resolve({old_name: old_name, new_name: new_name})
                }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },

    add_type_fichier({commit}, new_type) {
      CPMSService.createItem(new_type, 'type-fichier')
        .then(response => {
          commit('ADD_TYPE_FICHIER', response.data)
        }).catch(error => {
        console.log(error)
      })
    }
}

export const getters = {}