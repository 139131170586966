import CPMSService from "@/services/CPMSService";

export const namespaced = true

export const state = {
    'project_list': [],
    'active_project': {},
}

export const mutations = {
    UPDATE_PROJECT_LIST(state, data) {
        state.project_list = data.results
    },
    SET_ACTIVE_PROJECT(state, data) {
        state.active_project = data
        localStorage.setItem('active_project', JSON.stringify(data))
    },
}

export const actions = {
    get_project_list({state, commit}) {
        CPMSService.getProjectList()
            .then(response => {
                commit("UPDATE_PROJECT_LIST", response.data)
            })
            .catch(error => {
                console.log(error)
            })
    },
    set_active_project({commit, dispatch}, data) {
        commit("SET_ACTIVE_PROJECT", data)
        dispatch('increment_component_key', null, {root:true})
    },
    get_project_by_id({commit, dispatch}, projet_id){
        CPMSService.getItem(projet_id, 'project')
          .then(response => {
            dispatch('set_active_project', response.data)
        })
        .catch(error => {
            console.log(error)
        })
    }
}

export const getters = {
    isActiveProject: state => {
        return !!state.active_project.projet_id
    }
}