<template>
  <v-autocomplete
      :value="value"
      :loading="loading"
      :items="!!value.iduu ? [...plot_list, value] : plot_list"
      item-text="iduu"
      return-object
      :search-input.sync="search"
      class="mx-4"
      flat
      hide-no-data
      :label="label"
      @input="$emit('input', $event)"
      :hint="hint || value.iduu ? value.nom_com + ' ' + value.section + ' ' + value.numero : ''"
      persistent-hint
      :rules='rules || [vr.parcelle_required]'
  >
    <template v-slot:prepend-inner>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="extra_data = !extra_data">
            <v-icon
                v-bind="attrs"
                v-on="on"
                :color="extra_data ? 'blue-grey darken-4' : 'blue-grey lighten-4'"
                large
            >mdi-magnify-plus-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Elargir la recherche</span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>

<script>
import CPMSService from "@/services/CPMSService";

export default {
  name: "BaseParcelleSelect",
  props: {
    'label': String,
    'value': {
      type: Object,
      default: () => {
        return {
          nom_com: null,
          iduu: null
        }
      }
    },
    'rules': Array,
    'hint': String,
  },
  data() {
    return {
      loading: false,
      plot_list: [],
      search: '',
      extra_data: false,
    }
  },
  methods: {
    get_plot_list(v) {
      this.loading = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        CPMSService.getPlotList({search: v, limit: 20, extra: this.extra_data})
            .then(response => {
              this.plot_list = response.data.results
              this.loading = false
            }).catch(error => {
          console.log(error)
        })
      }, 500)
    },
  },
  watch: {
    search(val) {
      val && val !== this.value.iduu && this.get_plot_list(val)
    },
    extra_data() {
      this.search && this.search !== this.value.iduu && this.get_plot_list(this.search)
    }
  },
}
</script>

<style scoped>

</style>