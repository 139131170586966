<template>
  <v-autocomplete
      :value="value"
      :loading="loading"
      :items="!!value.nom_complet ? [...personne_list, value] : personne_list"
      item-text="nom_complet"
      return-object
      :search-input.sync="search"
      class="mx-4"
      flat
      hide-no-data
      :label="label"
      @input="$emit('input', $event)"
      :hint="hint"
      persistent-hint
      :rules='rules || [vr.personne_required]'
      no-filter
  >
        <template v-slot:prepend-inner>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="extra_data = !extra_data">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="extra_data ? 'blue-grey darken-4' : 'blue-grey lighten-4'"
                    large
                >mdi-magnify-plus-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Elargir la recherche</span>
          </v-tooltip>
        </template>
  </v-autocomplete>
</template>

<script>
import CPMSService from "@/services/CPMSService";
export default {
  name: "BasePersonneSelect",
  props: {
    'label': String,
    'value': {
      type: Object,
      default: () => {
        return {}
      }
    },
    'rules': Array,
    'hint': String,
    'filter': {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data() {
    return {
      loading: false,
      personne_list: [],
      search: '',
      extra_data: false,
    }
  },
  methods: {
    get_personne_list(v) {
      this.loading = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        CPMSService.getPersonneList({search: v, limit: 20, extra: this.extra_data, filter: this.filter})
            .then(response => {
              this.personne_list = response.data.results
              this.loading = false
            }).catch(error => {
          console.log(error)
        })
      }, 500)
    },
  },
  watch: {
    search(val) {
      val && val !== this.value.nom_complet && this.get_personne_list(val)
    },
    extra_data() {
      this.search && this.search !== this.value.nom_complet && this.get_personne_list(this.search)
    }
  },
}
</script>

<style scoped>

</style>