var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto mb-10",attrs:{"max-width":"550"}},[_c('v-list',[_c('v-card-title',{staticClass:"font-weight-bold text-h5"},[_c('v-spacer'),_vm._v(" "+_vm._s(_vm.c.type)+" : "+_vm._s(_vm.c.numero)+" "),_c('v-spacer'),_c('Cform',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var editItem = ref.editItem;
var deleteItem = ref.deleteItem;
var addItem = ref.addItem;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return addItem()}}},on),[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-book-plus-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Créer un Contrat")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return editItem(_vm.c)}}},on),[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-book-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Créer/Modifier Contrat")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return deleteItem(_vm.c)}}},on),[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-book-remove-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer ce contrat")])])]}}])})],1),_c('v-divider'),_c('v-card-text',[_c(_vm.contratComponent,{tag:"component",attrs:{"c":_vm.c}})],1)],1)],1),_c('TableParcelles'),_c('br'),_c('TablePersonnes'),_c('br'),_c('table-fichiers',{attrs:{"parent":"Contrat"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }