<template>
  <div>
    <slot :addItem="addItem"></slot>
    <v-dialog v-model="dialogAdd" max-width="550px" persistent>
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">Importer les parcelles d'une commune</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <BaseCommuneSelect
                :commune="commune"
                @input="(commune) => this.commune = commune"
            ></BaseCommuneSelect>
          </v-row>

          <v-row v-if="!!this.commune.nom">
            <v-col class="d-flex justify-center">
              Commune: {{ this.commune.nom }}<br/>
              Code INSEE: {{ this.commune.code }}<br/>
              Département: {{ this.commune.departement.nom }}<br/>
              Région: {{ this.commune.region.nom }}<br/>
              Population: {{ this.commune.population }} hab.<br/>
            </v-col>
          </v-row>
          <v-row v-if="inprogress">
            <v-progress-linear
                indeterminate
                color="blue darken-1"
            ></v-progress-linear>
          </v-row>
          <v-row class="d-flex justify-center" v-if="inprogress">
            Données en cours de téléchargement, veuillez patienter
          </v-row>
        </v-card-text>
        <v-card-actions v-if="!inprogress">
          <v-spacer></v-spacer>
          <v-btn color="blue-grey" text @click="closeItem">Annuler</v-btn>
          <v-btn color="blue-grey" text @click="downloadItem">Télécharger</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import CPMSService from "@/services/CPMSService";
import {mapActions, mapState} from "vuex";

export default {
  name: "Plotform",
  data() {
    return {
      inprogress: false,
      dialogAdd: false,
      commune: {},
    }
  },
  methods: {
    ...mapActions('plot', [
      'get_plot_list',
      'download_plots',
    ]),
    addItem: function () {
      this.dialogAdd = true
    },
    closeItem: function () {
      this.dialogAdd = false
      this.commune = {}
    },
    downloadItem: function () {
      this.inprogress = true
      this.download_plots({code_insee: this.commune.code}).then((response) => {
        this.closeItem()
        this.inprogress = false
      })
    },
  },
}
</script>

<style scoped>

</style>