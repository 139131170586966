var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mb-10",attrs:{"max-width":"800","elevation":"0"}},[(_vm.p.personne_morale)?_c('div',[_c('v-row',[_c('div',{staticClass:"text-h4"},[_c('span',[_vm._v(_vm._s(_vm.p.personne_morale.nom))]),(_vm.p.personne_morale.forme_juridique)?_c('span',[_vm._v(" ("+_vm._s(_vm.p.personne_morale.forme_juridique)+")")]):_vm._e(),_c('p-form',{attrs:{"ptype":"pm"},scopedSlots:_vm._u([{key:"boutons",fn:function(ref){
var editItem = ref.editItem;
var newItem = ref.newItem;
var deleteItem = ref.deleteItem;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":editItem}},[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return newItem('pm')}}},[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-plus-circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return deleteItem(_vm.p)}}},[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("mdi-delete")])],1)]}}],null,false,237728911)})],1)]),_c('v-row',[(_vm.p.siren)?_c('span',[_vm._v("Siren: "+_vm._s(_vm.p.siren))]):_vm._e(),(_vm.p.rcs)?_c('span',[_vm._v("RCS: "+_vm._s(_vm.p.rcs))]):_vm._e()]),_c('br'),_c('bloc-item',{attrs:{"itemArray":_vm.p.personne_morale.contacts,"metaData":_vm.contactMetaData},scopedSlots:_vm._u([{key:"template",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.personne.nom_complet)+" "),_c('v-btn',{attrs:{"icon":"","to":{ name: 'personne-physique', params: { id: item.personne.personne_physique_id }}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue-grey","small":""}},[_vm._v("mdi-account")])],1),_c('br'),_vm._v(" "+_vm._s(item.qualite)),(item.qualite)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.personne.fav_tel)),(item.personne.fav_tel)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.personne.fav_mail)),(item.personne.fav_mail)?_c('br'):_vm._e()]}},{key:"formtemplate",fn:function(ref){
var editedItem = ref.editedItem;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"type":"text","label":"Qualité"},model:{value:(editedItem.qualite),callback:function ($$v) {_vm.$set(editedItem, "qualite", $$v)},expression:"editedItem.qualite"}}),_c('BasePersonneSelect',{attrs:{"value":editedItem.personne,"label":"Nom","hint":((editedItem.personne.fav_tel || '') + " " + (editedItem.personne.fav_mail || '')),"filter":"pp"},on:{"input":function (data) {editedItem.personne = data; editedItem.personne_id = data.personne_id}}})]}}],null,false,2338426937)}),_c('bloc-item',{attrs:{"itemArray":_vm.p.adresses,"metaData":_vm.adresseMetaData},scopedSlots:_vm._u([{key:"template",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ligne_1)),(item.ligne_1)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.ligne_2)),(item.ligne_2)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.code_postal)+" "+_vm._s(item.commune)),_c('br'),_vm._v(" "+_vm._s(item.pays)+" ")]}},{key:"formtemplate",fn:function(ref){
var editedItem = ref.editedItem;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"type":"text","label":"Ligne 1","rules":[rules.required]},model:{value:(editedItem.ligne_1),callback:function ($$v) {_vm.$set(editedItem, "ligne_1", $$v)},expression:"editedItem.ligne_1"}}),_c('v-text-field',{attrs:{"type":"text","label":"Ligne 2"},model:{value:(editedItem.ligne_2),callback:function ($$v) {_vm.$set(editedItem, "ligne_2", $$v)},expression:"editedItem.ligne_2"}}),_c('v-text-field',{attrs:{"type":"text","label":"Code postal","rules":[rules.required]},model:{value:(editedItem.code_postal),callback:function ($$v) {_vm.$set(editedItem, "code_postal", $$v)},expression:"editedItem.code_postal"}}),_c('v-text-field',{attrs:{"type":"text","label":"Commune","rules":[rules.required]},model:{value:(editedItem.commune),callback:function ($$v) {_vm.$set(editedItem, "commune", $$v)},expression:"editedItem.commune"}}),_c('v-text-field',{attrs:{"type":"text","label":"Pays","rules":[rules.required]},model:{value:(editedItem.pays),callback:function ($$v) {_vm.$set(editedItem, "pays", $$v)},expression:"editedItem.pays"}})]}}],null,false,1865638305)}),_c('bloc-item',{attrs:{"itemArray":_vm.p.telephones,"metaData":_vm.telephoneMetaData},scopedSlots:_vm._u([{key:"template",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type_numero)+": "+_vm._s(item.numero)+" ")]}},{key:"formtemplate",fn:function(ref){
var editedItem = ref.editedItem;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"type":"text","label":"Indicatif","rules":[rules.required]},model:{value:(editedItem.indicatif),callback:function ($$v) {_vm.$set(editedItem, "indicatif", $$v)},expression:"editedItem.indicatif"}}),_c('v-text-field',{attrs:{"type":"text","label":"Numéro","rules":[rules.required]},model:{value:(editedItem.numero),callback:function ($$v) {_vm.$set(editedItem, "numero", $$v)},expression:"editedItem.numero"}}),_c('v-text-field',{attrs:{"type":"text","label":"Type de numéro","rules":[rules.required]},model:{value:(editedItem.type_numero),callback:function ($$v) {_vm.$set(editedItem, "type_numero", $$v)},expression:"editedItem.type_numero"}})]}}],null,false,2070211132)}),_c('bloc-item',{attrs:{"itemArray":_vm.p.emails,"metaData":_vm.emailMetaData},scopedSlots:_vm._u([{key:"template",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.adresse)+" ")]}},{key:"formtemplate",fn:function(ref){
var editedItem = ref.editedItem;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"type":"text","label":"adresse","rules":[rules.required, rules.email]},model:{value:(editedItem.adresse),callback:function ($$v) {_vm.$set(editedItem, "adresse", $$v)},expression:"editedItem.adresse"}})]}}],null,false,4211402349)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }