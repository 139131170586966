<template>
  <v-card class="mx-auto mb-10" max-width="800" elevation="0">
    <div v-if="p.personne_morale">
      <v-row>
        <div class="text-h4">
          <span>{{ p.personne_morale.nom }}</span>
          <span v-if="p.personne_morale.forme_juridique"> ({{ p.personne_morale.forme_juridique }})</span>
          <p-form ptype="pm">
            <template v-slot:boutons="{editItem, newItem, deleteItem}">
              <v-btn icon @click="editItem">
                <v-icon color="blue-grey">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="newItem('pm')">
                <v-icon color="blue-grey">mdi-plus-circle</v-icon>
              </v-btn>
              <v-btn icon @click="deleteItem(p)">
                <v-icon color="blue-grey">mdi-delete</v-icon>
              </v-btn>
            </template>
          </p-form>
        </div>
      </v-row>
      <v-row>
        <span v-if="p.siren">Siren: {{ p.siren }}</span>
        <span v-if="p.rcs">RCS: {{ p.rcs }}</span>
      </v-row>
      <br>
      <bloc-item :itemArray="p.personne_morale.contacts" :metaData="contactMetaData">
        <template v-slot:template="{item}">
          {{ item.personne.nom_complet }}
          <v-btn icon :to="{ name: 'personne-physique', params: { id: item.personne.personne_physique_id }}">
            <v-icon color="blue-grey" small class="mr-2">mdi-account</v-icon>
          </v-btn>
          <br>
          {{ item.qualite }}<br v-if="item.qualite">
          {{ item.personne.fav_tel }}<br v-if="item.personne.fav_tel">
          {{ item.personne.fav_mail }}<br v-if="item.personne.fav_mail">
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.qualite" label="Qualité"></v-text-field>
          <BasePersonneSelect
              :value="editedItem.personne"
              label="Nom"
              @input="(data) => {editedItem.personne = data; editedItem.personne_id = data.personne_id}"
              :hint="`${editedItem.personne.fav_tel || ''} ${editedItem.personne.fav_mail || ''}`"
              filter="pp"
          ></BasePersonneSelect>
        </template>
      </bloc-item>
      <bloc-item :itemArray="p.adresses" :metaData="adresseMetaData">
        <template v-slot:template="{item}">
          {{ item.ligne_1 }}<br v-if="item.ligne_1">
          {{ item.ligne_2 }}<br v-if="item.ligne_2">
          {{ item.code_postal }} {{ item.commune }}<br>
          {{ item.pays }}
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.ligne_1" label="Ligne 1"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.ligne_2" label="Ligne 2"></v-text-field>
          <v-text-field type="text" v-model="editedItem.code_postal" label="Code postal"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.commune" label="Commune"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.pays" label="Pays" :rules="[rules.required]"></v-text-field>
        </template>
      </bloc-item>
      <bloc-item :itemArray="p.telephones" :metaData="telephoneMetaData">
        <template v-slot:template="{item}">
          {{ item.type_numero }}: {{ item.numero }}
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.indicatif" label="Indicatif"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.numero" label="Numéro" :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.type_numero" label="Type de numéro"
                        :rules="[rules.required]"></v-text-field>
        </template>
      </bloc-item>
      <bloc-item :itemArray="p.emails" :metaData="emailMetaData">
        <template v-slot:template="{item}">
          {{ item.adresse }}
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.adresse" label="adresse"
                        :rules="[rules.required, rules.email]"></v-text-field>
        </template>
      </bloc-item>
    </div>
  </v-card>
</template>

<script>

import BlocItem from "@/components/BlocItem"
import Pform from "@/components/Pform"
import {mapActions, mapState} from 'vuex'

export default {
  name: "FicheIDPM",
  components: {
    "bloc-item": BlocItem,
    "p-form": Pform,
  },
  data: function () {
    return {
      adresseMetaData: {
        titre: "Adresse",
        delMessage: "Voulez-vous supprimer cette adresse ?",
        editMessage: "Modifier Adresse",
        createMessage: "Ajouter une adresse",
        editedIdKey: "adresse_id",
        endpoint: 'adresse',
        groupedCards: false
      },
      telephoneMetaData: {
        titre: "Téléphone",
        delMessage: "Voulez-vous supprimer ce numéro ?",
        editMessage: "Modifier Téléphone",
        createMessage: "Ajouter un numéro téléphone",
        editedIdKey: "telephone_id",
        endpoint: 'telephone',
        groupedCards: true
      },
      emailMetaData: {
        titre: "Email",
        delMessage: "Voulez-vous supprimer ce E-mail ?",
        editMessage: "Modifier Email",
        createMessage: "Ajouter un e-mail",
        editedIdKey: "email_id",
        endpoint: 'email',
        groupedCards: true
      },
      contactMetaData: {
        titre: "Contact",
        delMessage: "Voulez-vous supprimer ce contact ?",
        editMessage: "Modifier Contact",
        createMessage: "Ajouter un contact",
        editedIdKey: "contact_id",
        endpoint: 'contact',
        groupedCards: false
      },
    }
  },
  computed: {
    ...mapState({
      p: state => state.p.p
    }),
  }
}
</script>

<style scoped>

</style>