import Vue from 'vue'
import VueRouter from 'vue-router'
import PersonnePhysique from "../views/PersonnePhysique"
import PersonneMorale from "../views/PersonneMorale"
import Personnes from "../views/Personnes"
import Parcelles from "../views/Parcelles"
import Parcelle from "../views/Parcelle"
import RegisterUser from "../views/RegisterUser"
import LoginUser from "../views/LoginUser"
import Projets from "../views/Projets"
import store from "../store"
import Contrats from "../views/Contrats"
import Contrat from "../views/Contrat"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: {name: "projets"},
    meta: {
      doesnotrequireAuth: true,
      doesnotrequireProject: true
    }
  },
  {
    path: "/personnes",
    name: "personnes",
    component: Personnes,
    meta: {parent: 'personnes'}
  },
  {
    path: "/personnes/personne-physique:id",
    name: "personne-physique",
    component: PersonnePhysique,
    props: true,
    meta: {parent: 'personnes'}
  },
  {
    path: "/personnes/personne-morale:id",
    name: "personne-morale",
    component: PersonneMorale,
    props: true,
    meta: {parent: 'personnes'}
  },
  {
    path: "/parcelles",
    name: "parcelles",
    component: Parcelles,
    meta: {parent: 'parcelles'}
  },
  {
    path: "/parcelles/parcelle:id",
    name: "parcelle",
    component: Parcelle,
    props: true,
    meta: {
      parent: 'parcelles',
    }
  },
  {
    path: "/contrats",
    name: "contrats",
    component: Contrats,
    meta: {parent: 'contrats'}
  },
  {
    path: "/contrats/contrat:id",
    name: "contrat",
    component: Contrat,
    props: true,
    meta: {parent: 'contrats'}
  },
  {
    path: "/projets",
    name: "projets",
    component: Projets,
    meta: {
      doesnotrequireProject: true
    }
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: RegisterUser,
  //   meta: {
  //     doesnotrequireAuth: true,
  //     doesnotrequireProject: true
  //   }
  // },
  {
    path: "/login",
    name: "login",
    component: LoginUser,
    meta: {
      doesnotrequireAuth: true,
      doesnotrequireProject: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.user.user || localStorage.getItem('user')
  const is_active_project = !!store.state.project.active_project.projet_id || !!Object.keys(!!localStorage.getItem('active_project')).length || false
  if (!to.meta.doesnotrequireAuth && !loggedIn) {
    const dest = window.location.pathname + window.location.search
    next({name: "login", query: { from: dest } })
  } else if (!to.meta.doesnotrequireProject && !is_active_project) {
    !!to.query.projet_id ? store.dispatch('project/get_project_by_id', to.query.projet_id).then(() => next()) : next({name: "projets"})
  } else {
    next()
  }
})

export default router
