<template>
  <div>
    <div class="text-center text-h3 ma-10">Bienvenue</div>
    <v-card class="mx-auto" max-width="400px" elevation="3">
      <v-form
          v-model="form_valid"
          @submit.prevent="register"
      >
        <v-card-title class="justify-center">Créer un compte</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    name="cpms-email"
                    :rules="[vr.required, vr.email]"
                    v-model="email"
                    label="E-mail"
                    type="text"
                    autocomplete="username"
                    @input="errors.email = errors.non_field_errors = []"
                    :error-messages="errors.email ? errors.email[0] : ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    :rules=[vr.required]
                    v-model="password1"
                    label="Mot de passe"
                    :type="eye ? 'text' : 'password'"
                    :append-icon="eye ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="eye = !eye"
                    name="cpms-password"
                    autocomplete="new-password"
                    @input="errors.password1 = errors.non_field_errors = []"
                    :error-messages="errors.password1 ? errors.password1[0] : ''"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    :rules=[vr.required]
                    v-model="password2"
                    label="Répéter le mot de passe"
                    :type="eye ? 'text' : 'password'"
                    :append-icon="eye ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="eye = !eye"
                    name="cpms-password"
                    autocomplete="new-password"
                    @input="errors.password2 = errors.non_field_errors = []"
                    :error-messages="errors.password2 ? errors.password2[0] : ''"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row  v-if="errors.non_field_errors">
              <v-col class="pa-0">
                <p class="ma-0 red--text text-center" v-for="error in errors.non_field_errors">{{error}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type='submit' :disabled="!form_valid" color="blue darken-1" text>Créer un compte</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "RegisterUser",
  data: function () {
    return {
      form_valid: '',
      email: '',
      password1: '',
      password2: '',
      eye: false,
      errors: {}, //'email':[], 'password1':[], 'password2':[], 'non_field_errors':[]},
    }
  },
  methods: {
    register() {
      this.$store.dispatch('user/register', {
        email: this.email,
        password1: this.password1,
        password2: this.password2
      }).then(() => {
        this.$router.push({name: 'projets'})
      }).catch(error => {
        // console.log(error.response.data)
        this.errors = error.response.data

      })
    }
  }
}
</script>

<style scoped>

</style>