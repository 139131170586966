<template>
  <v-card class="mx-auto" max-width="1200">
    <v-data-table
        :headers="headers"
        :items="c_list"
        :search="search"
        :server-items-length="c_list_count"
        :options.sync="options"
        item-key="contrat_id"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        :footer-props="{'items-per-page-options': [10, 30, 100],}"
        :key="componentKey"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher un contrat"
              @input="va_chercher"
          ></v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch class="pl-10" v-model="extra_data" label="Recherche élargie"></v-switch>
              </div>
            </template>
            <span>Affiche tous les contrats de la base de données<br/>plutôt que ceux du projet uniquement</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <Cform v-slot="{addItem}">
              <v-tooltip left>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" icon @click.stop="addItem()">
                    <v-icon color="blue-grey">mdi-book-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>Créer un Contrat</span>
              </v-tooltip>
          </Cform>
        </v-toolbar>
      </template>
      <template v-slot:item.numero="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            {{ item.numero }}
            <v-btn icon :to="{name: 'contrat', params: {id: item.contrat_id}}">
              <v-icon small v-bind="attrs" v-on="on">mdi-book-outline</v-icon>
            </v-btn>
          </template>
          <span>Détails du contrat</span>
        </v-tooltip>
      </template>
      <template v-slot:item.date_signature="{item}">
        <span v-if="item.date_signature">{{ ISOtoUSR(item.date_signature) }}</span>
      </template>
      <template v-slot:item.parcelles="{ item }">
        <span v-for="(parcelle, index) in item.parcelles">
          {{ parcelle.slice(8, 10).replace('0', '') }} {{ parseInt(parcelle.slice(10)) }}
          <span v-if="index !== Object.keys(item.parcelles).length - 1">, </span>
        </span>
      </template>
      <template v-slot:item.personnes="{item}">
        <div v-for="(personne, index) in item.personnes" v-if="index+1 <= 3">
          {{ personne }}
          <span v-if="Object.keys(item.parcelles).length > 3 && index+1 === 3">...</span>
        </div>
      </template>
      <template v-slot:item.actions="{item}">
        <Cform v-slot="{deleteItem}">
            <v-tooltip left>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" icon @click.stop="deleteItem(item)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer ce contrat</span>
            </v-tooltip>
        </Cform>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import {format_functions,} from '../mixins/UtilityMixin'
import Cform from "@/components/Cform"

export default {
  name: "Contrats",
  components: {Cform},
  mixins: [format_functions],
  data() {
    return {
      titre_page: "Liste des contrats",
      loading: false,
      search: '',
      options: {},
      extra_data: false,
      headers: [
        {text: 'Numéro', value: 'numero', align: 'start', sortable: true},
        {text: 'Type', value: 'type', align: 'start', sortable: true},
        // {text: 'Date de signature', value: 'date_signature', align: 'start', sortable: true},
        {text: 'Parcelles', value: 'parcelles', align: 'start', sortable: false},
        {text: 'Personnes', value: 'personnes', align: 'start', sortable: false},
        {text: 'Supprimer', value: 'actions', align: 'center', sortable: false}
      ],
    }
  },
  watch: {
    all_options: {
      handler(oldval, newval) {
        this.loading = true
        this.get_c_list({
          "page": this.options.page,
          "itemsPerPage": this.options.itemsPerPage,
          "search": this.search,
          'extra': this.extra_data
        })
            .then(() => {
              this.loading = false
            })
      },
      deep: true,
    },
  },
  activated() {
    this.update_page(this.titre_page)
  },
  methods: {
    ...mapActions([
      'update_page',
    ]),
    ...mapActions('c', [
      'get_c_list',
    ]),
    va_chercher: function (input) {
      this.loading = true
      this.options.page = 1
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.get_c_list({
          "page": this.options.page,
          "itemsPerPage": this.options.itemsPerPage,
          "search": input,
          'extra': this.extra_data
        }).then(() => {
          this.loading = false
        })
      }, 500)
    }
  },
  computed: {
    ...mapState({
      c_list: state => state.c.c_list,
      c_list_count: state => state.c.c_list_count,
      componentKey: state => state.componentKey
    }),
    all_options() {
      return {...this.options, "extra_data": this.extra_data}
    },
  }
}
</script>

<style scoped>

</style>