<template>
  <v-dialog
      v-model="dialogEditMain"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{on: dialog}">
      <v-tooltip right close-delay="500" open-delay="500">
        <template v-slot:activator="{on: tooltip}">
          <v-icon v-on="{...dialog, ...tooltip}">mdi-pencil</v-icon>
        </template>
        <span>Ajouter / Supprimer un type de pièce jointe</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto" max-width="600">
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="types_fichiers_list"
            sort-by="nom_type"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer/>
              <v-toolbar-title class="text-h6">Types de pièces jointes</v-toolbar-title>
              <v-spacer/>
              <v-dialog
                  v-model="dialogEdit"
                  persistent
                  max-width="400px"
              >
                <template v-slot:activator="{on: dialog}">
                  <v-tooltip left>
                    <template v-slot:activator="{on: tooltip}">
                      <v-btn icon v-on="{...dialog, ...tooltip}">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Créer un nouveau type de pièce jointe</span>
                  </v-tooltip>
                </template>
                <v-form
                    v-model="form_valid"
                    @submit.prevent="saveItem"
                    ref="form"
                >
                  <v-card>
                    <v-card-title class="justify-center">Créer/Modifier un type de pièce jointe
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                          v-model="editedItem.nom_type"
                          :rules='[vr.required, vr.type_fichier_unique(types_fichiers_list, editedItem), vr.maxlength(50)]'
                          label="Intitulé"
                          type="text"
                          counter="50"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue-grey" text @click.stop.prevent="close">Annuler</v-btn>
                      <v-btn type='submit' color="blue-grey" :disabled="!form_valid || form_unmodified" text>
                        Sauvegarder
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>

              </v-dialog>
              <v-dialog
                  v-model="dialogDelete"
                  persistent
                  max-width="600px"
              >
                <v-card>
                  <v-card-title class="justify-center">Voulez-vous vraiment supprimer ce type de pièce jointe ?
                  </v-card-title>
                  <v-card-title class="justify-center font-italic">{{ editedItem.nom_type }}</v-card-title>
                  <v-card-text class="justify-center">
                    <p class="text-justify">
                      Cette modification affectera le type de toutes les pièces jointes dans tous les projets.
                      Si vous souhaitez continuer, sélectionnez un type de remplacement et cliquez sur
                      "Supprimer". Sinon cliquez sur "Annuler"
                    </p>
                    <br/>
                    <v-select
                        :items="types_fichiers_list_tronque"
                        v-model="replacement_type"
                        item-text='nom_type'
                        label="Statut de remplacement"
                        :menu-props="{offsetY: true}"
                        return-object
                    ></v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey" text @click="close">Annuler</v-btn>
                    <v-btn color="blue-grey"
                           :disabled="!replacement_type.typefichier_id"
                           text
                           @click="deleteItemConfirm">Supprimer
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>


            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click.stop.prevent="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click.stop.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey" text @click="dialogEditMain = false">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {isEqual} from "lodash";

export default {
  name: "TableTypesFichiers",
  props: ["referent"],
  data: function () {
    return {
      dialogEditMain: false,
      dialogEdit: false,
      dialogDelete: false,
      headers: [
        {text: 'Type', value: 'nom_type'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      editedItem: {},
      defaultItem: {},
      form_valid: false,
      replacement_type: {},

    }
  },

  created() {
    this.get_types_fichiers_list({referent: this.referent})
  },
  methods: {
    ...mapActions('types_fichiers', [
      'get_types_fichiers_list',
      'update_type_fichier',
      'add_type_fichier',
      'delete_type_fichier'
    ]),
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    saveItem() {
      if (!!this.editedItem.typefichier_id) {
        this.update_type_fichier(this.editedItem)
            .then((response) => {
              this.$emit('update_your_data_bro', response)
              this.close()
            })
      } else {
        this.editedItem.referent = this.referent
        this.add_type_fichier(this.editedItem)
            .then(() => {
              this.close()
            })
      }
    },
    deleteItemConfirm() {
      this.delete_type_fichier({to_delete: this.editedItem, to_replace_with: this.replacement_type})
          .then((response) => {
            this.$emit('update_your_data_bro', response)
            this.close()
          })
    },
    close() {
      this.dialogEdit = false
      this.dialogDelete = false
      this.editedItem = {...this.defaultItem}
      this.replacement_type = {}
      !!this.$refs.form && this.$refs.form.resetValidation()
    },
  },
  computed: {
    ...mapState({
      types_fichiers_list: state => state.types_fichiers.types_fichiers_list,
    }),
    types_fichiers_list_tronque() {
      return this.types_fichiers_list.filter(element => element.typefichier_id !== this.editedItem.typefichier_id)
    },
    form_unmodified() {
      const originalItem = this.editedItem.typefichier_id ? this.types_fichiers_list.find(element => element.typefichier_id === this.editedItem.typefichier_id) : {}
      return isEqual(this.editedItem, originalItem)
    },
  },
}
</script>

<style scoped>

</style>