<template>
  <v-select
      :items="unites"
      :value="value"
      @input="$emit('input', $event)"
      :menu-props="{offsetY: true}"
      :label="label"
      item-text="abbreviation"
      item-value="abbreviation"
  >
    <template v-slot:item="{ item, on : select, attrs }">
      <v-list-item v-bind="attrs" v-on="select">
        <v-list-item-content class="my-0 py-0">
          {{ item.abbreviation }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import CPMSService from "@/services/CPMSService";
export default {
  name: "BaseUnitSelect",
  props: ["value", "label"],
  data() {
    return {
      unites: [],
    }
  },
  created() {
    CPMSService.getItemList("unite")
        .then((response) => {
          this.unites = response.data
        }).catch((error) => {
      console.log(error)
    })
  },
}
</script>

<style scoped>

</style>