<template>
  <v-card class="mx-auto mb-10" max-width="800" elevation="0">
    <div v-if="p.personne_physique">
      <v-row>
        <div class="text-h4">
          <span>{{p.personne_physique.civilite}} {{ p.personne_physique.nom_famille }} {{ p.personne_physique.prenom }}</span>
          <span class="text-h4 font-weight-light"> {{ p.personne_physique.deuxiemes_prenoms }}</span>
          <span v-if="p.personne_physique.nom_usage">
          (ep. {{ p.personne_physique.nom_usage }})
        </span>
          <p-form ptype="pp">
            <template v-slot:boutons="{editItem, newItem, deleteItem}">
              <v-btn icon @click.stop="editItem">
                <v-icon color="blue-grey">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click.stop="newItem('pp')">
                <v-icon color="blue-grey">mdi-plus-circle</v-icon>
              </v-btn>
              <v-btn icon @click.stop="deleteItem(p)">
                <v-icon color="blue-grey">mdi-delete</v-icon>
              </v-btn>
            </template>
          </p-form>
        </div>
      </v-row>
      <v-row>
        <span v-if="p.personne_physique.date_naissance">Né(e) le {{ ISOtoUSR(p.personne_physique.date_naissance) }}</span>
        <span v-if="p.personne_physique.lieu_naissance">, à {{ p.personne_physique.lieu_naissance }}</span>
        <span v-if="p.personne_physique.decede">, décédé(e)</span>
        <span v-if="p.personne_physique.date_deces">&nbsp;le {{ ISOtoUSR(p.personne_physique.date_deces) }}</span>
      </v-row>
      <br>
      <bloc-item :itemArray="p.adresses" :metaData="adresseMetaData">
        <template v-slot:template="{item}">
          {{ item.ligne_1 }}<br v-if="item.ligne_1">
          {{ item.ligne_2 }}<br v-if="item.ligne_2">
          {{ item.code_postal }} {{ item.commune }}<br>
          {{ item.pays }}
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.ligne_1" label="Ligne 1"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.ligne_2" label="Ligne 2"></v-text-field>
          <v-text-field type="text" v-model="editedItem.code_postal" label="Code postal"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.commune" label="Commune"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.pays" label="Pays" :rules="[rules.required]"></v-text-field>
        </template>
      </bloc-item>
      <bloc-item :itemArray="p.telephones" :metaData="telephoneMetaData">
        <template v-slot:template="{item}">
          {{ item.type_numero }}: {{ item.numero }}
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.indicatif" label="Indicatif"
                        :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.numero" label="Numéro" :rules="[rules.required]"></v-text-field>
          <v-text-field type="text" v-model="editedItem.type_numero" label="Type de numéro"
                        :rules="[rules.required]"></v-text-field>
        </template>
      </bloc-item>
      <bloc-item :itemArray="p.emails" :metaData="emailMetaData">
        <template v-slot:template="{item}">
          {{ item.adresse }}
        </template>
        <template v-slot:formtemplate="{editedItem, rules}">
          <v-text-field type="text" v-model="editedItem.adresse" label="adresse"
                        :rules="[rules.required, rules.email]"></v-text-field>
        </template>
      </bloc-item>
    </div>
  </v-card>
</template>

<script>

import BlocItem from "@/components/BlocItem"
import Pform from "@/components/Pform"
import moment from "moment"
import {mapActions, mapState} from 'vuex'
import {format_functions} from '@/mixins/UtilityMixin'

import CPMSService from "@/services/CPMSService"
import { saveAs } from 'file-saver'

export default {
  name: "FicheIDPP",
  components: {
    "bloc-item": BlocItem,
    "p-form": Pform,
  },
  mixins: [format_functions],
  data: function () {
    return {
      adresseMetaData: {
        titre: "Adresse",
        delMessage: "Voulez-vous supprimer cette adresse ?",
        editMessage: "Modifier Adresse",
        createMessage: "Ajouter une adresse",
        editedIdKey: "adresse_id",
        endpoint: 'adresse',
        groupedCards: false
      },
      telephoneMetaData: {
        titre: "Téléphone",
        delMessage: "Voulez-vous supprimer ce numéro ?",
        editMessage: "Modifier Téléphone",
        createMessage: "Ajouter un numéro téléphone",
        editedIdKey: "telephone_id",
        endpoint: 'telephone',
        groupedCards: true
      },
      emailMetaData: {
        titre: "Email",
        delMessage: "Voulez-vous supprimer cet E-mail ?",
        editMessage: "Modifier Email",
        createMessage: "Ajouter un e-mail",
        editedIdKey: "email_id",
        endpoint: 'email',
        groupedCards: true
      },
    }
  },
  computed: {
    ...mapState({
      p: state => state.p.p

    }),
  },
}
</script>

<style scoped>

</style>