<template>
  <div>
    <fiche-id-pm/>
    <table-droits></table-droits>
  </div>
</template>

<script>
import FicheIDPM from "@/components/FicheIDPM"
import TableDroits from "@/components/TableDroits"
import {mapActions, mapState} from 'vuex'
export default {
  name: "PersonneMorale",
  components: {"fiche-id-pm": FicheIDPM, "table-droits": TableDroits},
  props: ['id'],
  data: function () {
    return {
      titre_page: 'Détail Personne Morale',
    }
  },
  created() {
    this.get_p(this.id)
    this.update_page(this.titre_page)
  },
  methods: {
    ...mapActions([
        'update_page',
    ]),
    ...mapActions('p', [
        'get_p',
    ]),
  },
  watch: {
    componentKey() {
      this.get_p(this.id)
    },
  },
  computed: {
    ...mapState({
      componentKey: state => state.componentKey
    }),
  }
}
</script>

<style scoped>
</style>
