<template>
<div>
  <div v-for="error in errorList">
    <v-alert
        type="error"
        outlined
        dense
        dismissible
        class="my-3"
    >
      {{`${error.field}: ${error.alarme}`}}
    </v-alert>
  </div>
</div>
</template>

<script>
export default {
  name: "ErrorManager",
  props: ['serverErrors'],
  computed:{
    errorList(){
      let result = []
      Object.keys(this.serverErrors).forEach((field) => {
        this.serverErrors[field].forEach(er =>{
          result.push({'field': field, 'alarme': er})
        })
      })
      return result
    },

  }
}
</script>

<style scoped>

</style>