var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1000"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":this.c.personnes,"disable-pagination":"","hide-default-footer":"","sort-by":"personne.nom_complet","no-data-text":"Aucune personne"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h6"},[_vm._v("Signataires")]),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Voulez-vous supprimer cet ayant-droit ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeItem}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Oui")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"large":"","color":"blue-grey"}},[_vm._v("mdi-account-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Ajouter une personne à ce contrat")])])]}}]),model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveItem($event)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline"},[_vm._v("Ajouter/Modifier une personne")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('BasePersonneSelect',{attrs:{"value":_vm.editedItem.personne,"label":"Personne","rules":[_vm.vr.personne_required, _vm.vr.personne_unique(_vm.c.personnes, _vm.editedItem)]},on:{"input":function (data) {_vm.editedItem.personne = data; _vm.editedItem.personne_id = data.personne_id}}})],1),_c('v-row',[_c('v-spacer'),_c('error-manager',{attrs:{"serverErrors":_vm.serverErrors}}),_c('v-spacer')],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue-grey","text":""},on:{"click":_vm.closeItem}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.form_valid,"color":"blue-grey","text":""}},[_vm._v("Sauvegarder ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.personne.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","to":{name: item.personne.type === 'pp' ? 'personne-physique': 'personne-morale', params: {id: item.personne.personne_id}}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.personne.type === 'pp' ? 'mdi-account' : 'mdi-briefcase'))])],1)]}}],null,true)},[_c('span',[_vm._v("Plus d'info sur cette personne")])])]}},{key:"item.personne.fav_adr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.personne.fav_adr.ligne_1))]),(item.personne.fav_adr.ligne_1)?_c('br'):_vm._e(),_c('span',[_vm._v(_vm._s(item.personne.fav_adr.ligne_2))]),(item.personne.fav_adr.ligne_2)?_c('br'):_vm._e(),_c('span',[_vm._v(_vm._s(item.personne.fav_adr.code_postal)+" "+_vm._s(item.personne.fav_adr.commune))]),(item.personne.fav_adr.pays !== 'France')?_c('span',[_vm._v(" "+_vm._s(item.personne.fav_adr.pays))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }