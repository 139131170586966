<template>
  <v-card class="mx-auto" max-width="1000">
    <v-data-table
        :headers="fichiers_headers"
        :items="this.referent.fichiers"
        :items-per-page="10"
        :sort-by="['maj_le_utc']"
        class="elevation-2"
        no-data-text="Aucune pièce jointe"
    >
      <template v-slot:item.url_fichier="{item}">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon @click="openItem(item.url_fichier)">{{ icon(item.ext_fichier) }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.ext_fichier }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="openItem(item.url_fichier)">mdi-folder-open</v-icon>
        <v-icon small class="mr-2" @click="downloadItem(item)">mdi-tray-arrow-down</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Pièces jointes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogEdit" persistent max-width="400px">
            <template v-slot:activator="{on: dialog, attrs}">
              <v-tooltip left>
                <template v-slot:activator="{on: tooltip}">
                  <v-btn icon v-bind="attrs" v-on="{...dialog, ...tooltip}">
                    <v-icon large color="blue-grey">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Ajouter une pièce jointe</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title class="justify-center">
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                      ref="form"
                      v-model="form_valid"
                      @submit.prevent="saveItem"
                  >
                    <v-row v-if="!editedItem.fichier_id">
                      <v-col cols="12">
                        <v-file-input
                            v-model="editedItem.url_fichier"
                            :rules=[vr.required]
                            show-size
                            label="fichier"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <BaseTypeFichierSelect
                            :value="editedItem.type_fichier"
                            :referent="parent"
                            label="Ayant droit"
                            @input="(data) => {editedItem.type_fichier = data.nom_type; editedItem.type_fichier_id = data.typefichier_id}"
                            @update_your_data_bro="update_data($event)"
                        ></BaseTypeFichierSelect>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            :rules=[vr.maxlength(50)]
                            v-model="editedItem.description_fichier"
                            label="Description (optionnel)"
                            type="text"
                            counter="50"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-grey" text @click="close">Annuler</v-btn>
                <v-btn type='submit'
                       :disabled="!form_valid || form_unmodified"
                       color="blue-grey"
                       text
                       @click="saveItem"
                >
                  Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" persistent max-width="400px">
            <v-card>
              <v-card-title class="justify-center">Voulez-vous supprimer ce fichier ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import CPMSService from "@/services/CPMSService";
import {isEqual} from "lodash";
import TableTypesFichiers from "@/components/TableTypesFichiers";

export default {
  name: "TableFichiers",
  props: ['parent'],
  data: function () {
    return {
      form_valid: false,
      dialogEdit: false,
      dialogDelete: false,
      editedItem: {},
      fichiers_headers: [
        {text: '', value: 'url_fichier', width: '5%'},
        {text: 'Type', value: 'type_fichier'},
        {text: 'Description', value: 'description_fichier'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      parents: {
        'Parcelle': {
          vuex_object: 'plot',
          vuex_module: 'plot',
          parent_pk_field: 'parcelle_id',
          fk_field: 'parcelle',
        },
        'Contrat': {
          vuex_object: 'c',
          vuex_module: 'c',
          parent_pk_field: 'contrat_id',
          fk_field: 'contrat',
        }
      }
    }
  },
  computed: {
    referent() {
      return this.$store.state[this.parents[this.parent]['vuex_module']][this.parents[this.parent]['vuex_object']]
    },
    vuex_module() {
      return this.parents[this.parent]['vuex_module']
    },
    fk_field() {
      return this.parents[this.parent]['fk_field']
    },
    referent_id() {
      return this.referent[this.parents[this.parent]['parent_pk_field']]
    },
    formTitle() {
      return this.editedItem["fichier_id"] ? 'Modifier Fichier' : 'Ajouter un fichier'
    },
    form_unmodified() {
      const originalItem = this.editedItem["fichier_id"] ? this.referent.fichiers.find(element => element["fichier_id"] === this.editedItem["fichier_id"]) : {}
      return isEqual(this.editedItem, originalItem)
      // return false
    },
  },
  methods: {
    downloadItem(item) {
      CPMSService.download(item.url_fichier).then((response) => {
        const blob = new Blob([response.data])
        saveAs(blob, item.slug)
      })
    },
    openItem(url) {
      CPMSService.download(url).then((response) => {
        const file_format = response.headers['content-type']
        const url = window.URL.createObjectURL(new Blob([response.data], {type: file_format}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('target', "_blank")
        link.click()
        window.URL.revokeObjectURL(url)
      })
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    saveItem() {
      if (this.editedItem["fichier_id"]) {
        delete this.editedItem["url_fichier"]
        this.$store.dispatch(`${this.vuex_module}/update_item`, {
          "array": this.referent.fichiers,
          "item": this.editedItem,
          "idKey": "fichier_id",
          "endpoint": "fichier"
        }).then(() => {
          this.close()
        })
      } else {
        this.editedItem[this.fk_field] = this.referent_id
        this.$store.dispatch(`${this.vuex_module}/add_item`, {
          "item": this.editedItem,
          "array": this.referent.fichiers,
          "endpoint": "fichier"
        }).then(() => (this.close()))
      }
    },
    deleteItemConfirm() {
      this.$store.dispatch(`${this.vuex_module}/delete_item`, {
        "item": this.editedItem,
        "array": this.referent.fichiers,
        "idKey": "fichier_id",
        "endpoint": "fichier"
      }).then(() => {
        this.close()
      })
    },
    close() {
      this.dialogEdit = false
      this.dialogDelete = false
      !!this.$refs.form && this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    icon(file_ext) {
      if (['png', 'pdf', 'jpg', 'png', 'gif'].includes(file_ext)) {
        return `mdi-file-${file_ext}-box`
      } else {
        return 'mdi-text-box'
      }
    },
    update_data(data) {
      this.referent.fichiers.forEach(el => {
        el.type_fichier = el.type_fichier === data.old_name ? data.new_name : el.type_fichier
      })
      this.editedItem.type_fichier = this.referent.fichiers.find(el => el.fichier_id === this.editedItem.fichier_id).type_fichier
    }
  },
}
</script>

<style scoped>

</style>