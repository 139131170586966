<template>
  <v-dialog
      v-model="dialogEditMain"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{on: dialog}">
      <v-tooltip right close-delay="500" open-delay="500">
        <template v-slot:activator="{on: tooltip}">
            <v-icon v-on="{...dialog, ...tooltip}">mdi-pencil</v-icon>
        </template>
        <span>Modifier la liste des droits</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto" max-width="600">
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="type_droit_list"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer/>
              <v-toolbar-title class="text-h6">Droits fonciers</v-toolbar-title>
              <v-spacer/>
              <v-dialog
                  v-model="dialogEdit"
                  persistent
                  max-width="400px"
              >
                <template v-slot:activator="{on: dialog}">
                  <v-tooltip left>
                    <template v-slot:activator="{on: tooltip}">
                      <v-btn icon v-on="{...dialog, ...tooltip}">
                        <v-icon class="mr-2">mdi-text-box-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Créer un nouveau type de droit</span>
                  </v-tooltip>
                </template>
                <v-form
                    v-model="form_valid"
                    @submit.prevent="saveItem"
                    ref="form"
                >
                  <v-card>
                    <v-card-title class="justify-center">Renommer le type de droit</v-card-title>
                    <v-card-text>
                      <v-text-field
                          :rules='[vr.required, vr.droit_unique(type_droit_list, editedItem), vr.maxlength(50)]'
                          v-model="editedItem.description"
                          label="Intitulé"
                          type="text"
                          counter="50"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="blue-grey" text @click.stop.prevent="close">Annuler</v-btn>
                      <v-btn type='submit' color="blue-grey" :disabled="!form_valid || form_unmodified" text>
                        Sauvegarder
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-dialog
                  v-model="dialogDelete"
                  persistent
                  max-width="550px"
              >
                <v-card>
                  <v-card-title class="justify-center">Voulez-vous vraiment supprimer ce type de Droit ?
                  </v-card-title>
                  <v-card-title class="justify-center font-italic">{{ editedItem.description }}</v-card-title>
                  <v-card-text class="justify-center">
                    <p class="text-justify">
                      Cette modification affectera tous les droits possédant ce statut dans tous les projets.
                      Si vous souhaitez continuer, sélectionnez un type de droit de remplacement et cliquez sur
                      "Supprimer". Sinon cliquez sur "Annuler"
                    </p>
                    <br/>
                    <v-select
                        :items="type_droit_list_tronque"
                        v-model="replacement_droit"
                        item-text='description'
                        label="Type de droit de remplacement"
                        :menu-props="{offsetY: true}"
                        return-object
                    ></v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey" text @click="close">Annuler</v-btn>
                    <v-btn color="blue-grey" :disabled="!replacement_droit.type_droit_id" text
                           @click="deleteItemConfirm">Supprimer
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click.stop.prevent="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click.stop.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey" text @click="dialogEditMain = false">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CPMSService from "@/services/CPMSService";
import {mapActions, mapState} from "vuex";
import {isEqual} from "lodash";

export default {
  name: "TableTypeDroits",
  data() {
    return {
      headers: [
        {text: 'Droit', value: 'description'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      form_valid: true,
      dialogEditMain: false,
      dialogEdit: false,
      dialogDelete: false,
      editedItem: {},
      replacement_droit: {},
    }
  },
  created(){
    this.get_type_droit_list()
  },
  computed: {
    ...mapState({
      type_droit_list: state => state.droit.type_droit_list
    }),
    type_droit_list_tronque() {
      return this.type_droit_list.filter(element => element.type_droit_id !== this.editedItem.type_droit_id)
    },
    form_unmodified() {
      const originalItem = this.editedItem.type_droit_id ? this.type_droit_list.find(element => element.type_droit_id === this.editedItem.type_droit_id) : {}
      return isEqual(this.editedItem, originalItem)
    },

  },
  methods: {
    ...mapActions('droit', [
        'get_type_droit_list',
        'delete_type_droit',
        'update_type_droit',
        'add_type_droit'

    ]),
    close() {
      this.dialogEdit = false
      this.dialogDelete = false
      this.editedItem = {}
      this.replacement_droit = {}
      !!this.$refs.form && this.$refs.form.resetValidation()
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.editedItem = Object.assign({}, item)
    },

    deleteItemConfirm() {
      this.delete_type_droit({to_delete: this.editedItem, to_replace_with: this.replacement_droit})
        .then(() => {this.close()})
    },

    saveItem() {
      if (!!this.editedItem.type_droit_id) {
        this.update_type_droit(this.editedItem)
          .then(() => {this.close()})
      } else {
        this.add_type_droit(this.editedItem)
          .then(() => {this.close()})
      }
    }
  },
}
</script>

<style scoped>

</style>