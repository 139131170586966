<template>
  <div>
    <v-card class="mx-auto mb-10" max-width="500">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold text-h5">Parcelle : {{ plot.iduu }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Département : {{ plot.code_dep }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Commune : {{ plot.nom_com }} (INSEE: {{ plot.code_dep }}{{ plot.code_com }})</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="plot.prefixe !== '000'">
        <v-list-item-content>
          <v-list-item-title>Préfixe : {{ plot.prefixe }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Section : {{ plot.section }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Numéro : {{ plot.numero }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="plot.supf">
        <v-list-item-content>
          <v-list-item-title>Superficie : {{ plot.supf }} m²</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-container>
            <v-row>
              <v-spacer/>
              <v-col cols="8">
                <BaseStatutParcelleSelect :key="componentKey"></BaseStatutParcelleSelect>
              </v-col>
              <v-spacer/>
            </v-row>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <table-droits-plot></table-droits-plot>
    <br/>
    <bloc-comment></bloc-comment>
    <table-fichiers parent="Parcelle"></table-fichiers>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import TableDroitsPlot from "@/components/TableDroitsPlot";
import BlocComment from '@/components/BlocComment'
import BaseStatutParcelleSelect from "@/components/form_elements/BaseStatutParcelleSelect";
import TableFichiers from "@/components/TableFichiers";
export default {
  name: "Parcelle",
  components: {
    "table-droits-plot": TableDroitsPlot,
    "bloc-comment": BlocComment,
    "BaseStatutParcelleSelect": BaseStatutParcelleSelect,
    "table-fichiers": TableFichiers
  },
  props: ['id'],
  data: function () {
    return {
      titre_page: 'Détail Parcelle',
    }
  },
  created() {
    this.get_plot(this.id).then(() => {
      this.update_page(this.titre_page + ': ' + this.plot.iduu)
    })
  },
  methods: {
    ...mapActions([
      'update_page',
    ]),
    ...mapActions('plot', [
      'get_plot',
    ]),
  },
  watch: {
    componentKey() {
      this.get_plot(this.id)
    },
  },
  computed: {
    ...mapState({
      plot: state => state.plot.plot,
      componentKey: state => state.componentKey
    }),
  }
}
</script>

<style scoped>

</style>