import CPMSService from "@/services/CPMSService";

export const namespaced = true

export const state = {
  'p': {},
  'p_list': [],
  'p_list_count': 0,
}

export const mutations = {
  UPDATE_P(state, p) {
    state.p = {...state.p, ...p}
  },

  DELETE_P(state, item) {
    state.p = {}
    const index = state.p_list.findIndex(element => element.personne_id === item.personne_id)
    state.p_list.splice(index, 1)
    state.p_list_count--
  },
  FLUSH_P(state) {
    state.p = {}
  },

  UPDATE_P_LIST(state, data) {
    state.p_list = data.results
    state.p_list_count = data.count
  },
  DELETE_ITEM(state, {index, array}) {
    array.splice(index, 1)
  },
  UPDATE_ITEM(state, {index, array, item}) {
    array.splice(index, 1, item)
  },
  ADD_ITEM(state, {item, array}) {
    array.push(item)
  },
}

export const actions = {
  get_p_list({commit}, {page=1, itemsPerPage=10, search="", extra=false}) {
    const offset = (page - 1) * itemsPerPage
    const limit = itemsPerPage
    CPMSService.getPersonneList({offset: offset, limit: limit, search: search, extra: extra})
      .then(response => {
        commit("UPDATE_P_LIST", response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  get_p({state, commit}, id) {
    if (this.state.p.p.personne_id !== id) {
      commit('FLUSH_P')
    }
    CPMSService.getPersonneDetail(id)
      .then(response => {
        commit('UPDATE_P', response.data)
      }).catch(
      error => {
        console.log(error)
      })
  },
  delete_p({state, commit}, p) {
    return CPMSService.deletePersonne(p.personne_id)
      .then(() => {
        commit('DELETE_P', p)
      }).catch(error => {
        console.log(error)
      })
  },
  create_pp({state, commit}, data) {
    return CPMSService.createPersonnePhysique(data)
      .then(response => {
        commit('UPDATE_P', {personne_physique: response.data})
      }).catch(
        error => {
          console.log(error)
        }
      )
  },
  update_pp({state, commit}, data) {
    return CPMSService.updatePersonnePhysique(data)
      .then(response => {
        commit('UPDATE_P', {personne_physique: response.data})
      }).catch(
        error => {
          console.log(error)
        })
  },
  create_pm({state, commit}, data) {
    return CPMSService.createPersonneMorale(data)
      .then(response => {
        commit('UPDATE_P', {personne_morale: response.data})
      }).catch(
        error => {
          console.log(error)
        }
      )
  },
  update_pm({state, commit}, data) {
    return CPMSService.updatePersonneMorale(data)
      .then(response => {
        commit('UPDATE_P', {personne_morale: response.data})
      }).catch(
        error => {
          console.log(error)
        })
  },
  delete_item({state, commit}, {item, array, idKey, endpoint}) {
    CPMSService.deleteItem(item[idKey], endpoint)
      .then(() => {
        const index = array.findIndex(element => element[idKey] === item[idKey])
        commit('DELETE_ITEM', {"index": index, "array": array})
      }).catch(error => {
      console.log(error)
    })
  },
  update_item({state, commit}, {idKey, array, item, endpoint}) {
    return new Promise((resolve, reject) => {
      CPMSService.updateItem(item[idKey], item, endpoint)
        .then(response => {
          const index = array.findIndex(element => element[idKey] === response.data[idKey])
          commit('UPDATE_ITEM', {"array": array, "index": index, "item": response.data})
          resolve(response)
        }).catch(error => {
          console.log(error)
        reject(error)
      })
    })
  },
  add_item({state, commit}, {item, array, endpoint}) {
    return new Promise((resolve, reject) => {
      CPMSService.createItem(item, endpoint)
        .then(response => {
          commit('ADD_ITEM', {"item": response.data, "array": array})
          resolve(response)
        }).catch(error => {
          reject(error)
      })
    })
  },
  update_star({commit}, {idKey, endpoint, array, item}) {
    CPMSService.patchItem(item[idKey], {prefere: !item.prefere}, endpoint)
      .then(response => {
        const index = array.findIndex(element => element[idKey] === response.data[idKey])
        commit('UPDATE_ITEM', {"array": array, "index": index, "item": response.data})
      }).catch(error => {
      console.log(error)
    })
    const oldstars = array.filter(i => i.prefere === true && i[idKey] !== item[idKey])
    for (let i = 0; i < oldstars.length; i++) {
      CPMSService.patchItem(oldstars[i][idKey], {prefere: false}, endpoint)
        .then(response => {
          const index = array.findIndex(element => element[idKey] === response.data[idKey])
          commit('UPDATE_ITEM', {"array": array, "index": index, "item": response.data})
        }).catch(error => {
        console.log(error)
      })
    }
  }
}

export const getters = {}