<template>
  <div>
    <slot :deleteItem="deleteItem" :editItem="editItem" :addItem="addItem"></slot>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="justify-center">Voulez-vous supprimer ce Contrat ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeItem">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" persistent max-width="500px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">Modifier le contrat</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-form
                ref="form"
                v-model="form_valid"
                lazy-validation
            >
              <component :is="contratComponent" :refs="this.$refs"/>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey" text @click="closeItem">Annuler</v-btn>
          <v-btn @click="saveItem()" :disabled="!form_valid" color="blue-grey" text>Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAdd" persistent max-width="500px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">Créer un contrat</span>
        </v-card-title>
        <v-card-subtitle class="text-center pt-5">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text>
                {{ contrat_type.full_name || 'Sélectionner un type de contrat' }}
                <v-icon class="pl-2">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in typesContrat"
                  :key="index"
                  @click="contrat_type = item"
              >
                <v-list-item-title>{{ item.full_name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-subtitle>
        <v-card-text>
          <v-form
              ref="form"
              v-model="form_valid"
              lazy-validation
          >
            <component :is="contratComponent" :refs="this.$refs"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey" text @click="closeItem">Annuler</v-btn>
          <v-btn @click="saveItem()" :disabled="!form_valid" color="blue-grey" text ref="plouf">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CPMSService from "@/services/CPMSService"

export default {
  name: "Cform",
  data() {
    return {
      contrat_type: '',
      typesContrat: [],
      dialogDelete: false,
      dialogEdit: false,
      dialogAdd: false,
      form_valid: true,
      serverErrors: {},
    }
  },
  methods: {
    ...mapActions('c', [
      'delete_c',
      'update_c',
      'add_c'
    ]),
    ...mapActions('edition', [
      'init_ei',
      'flush_ei',
      'update_ei'
    ]),
    deleteItem: function (item) {
      this.init_ei(item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.delete_c(this.editedItem).then((response) => {
        this.closeItem()
        this.$route.name !== 'contrats' && this.$router.push({name: 'contrats',})
      }).catch(error => {
        console.log(error)
      })
    },
    closeItem() {
      this.flush_ei()
      this.$refs.form && this.$refs.form.resetValidation()
      this.serverErrors = {}
      this.contrat_type = ''
      this.dialogAdd = false
      this.dialogEdit = false
      this.dialogDelete = false
    },
    editItem: function (item) {
      this.init_ei(item)
      this.dialogEdit = true
    },
    saveItem() {
      if (!!this.editedItem.contrat_id) {
        this.update_c(this.editedItem)
            .then(() => {
              this.closeItem()
            }).catch(error => {
          if (!!error.response && error.response.status === 400) {
            this.serverErrors = error.response.data
          }
        })
      } else {
        this.update_ei({'field': 'contrat_type', 'value': this.contrat_type.class_name})
        this.update_ei({'field': 'type', 'value': this.contrat_type.full_name})
        this.add_c(this.editedItem).then((response) => {
          this.closeItem()
          this.$router.push({name: "contrat", params: {id: response.data["contrat_id"]}})
        }).catch(error => {
          if (!!error.response && error.response.status === 400) {
            this.serverErrors = error.response.data
          }
        })
      }
    },
    addItem() {
      CPMSService.getItemList("contrat/type-contrat")
          .then((response) => {
            this.typesContrat = response.data
            this.dialogAdd = true
          }).catch((error) => {
        console.log(error)
      })
    },
  },
  computed: {
    ...mapState({
      editedItem: state => state.edition.editedItem
    }),
    contratComponent() {
      if ((!!this.dialogEdit && this.editedItem.type === 'Promesse de Bail') || (!!this.dialogAdd && this.contrat_type.class_name === 'PromesseDeBail')) {
        return () => import(/* webpackChunkName: "PromesseDeBail" */ "@/components/contrats/formulaires/PromesseDeBail_form")
      } else if ((!!this.dialogEdit && this.editedItem.type === 'Convention de Servitude') || (!!this.dialogAdd && this.contrat_type.class_name === 'ConventionDeServitude')) {
        return () => import(/* webpackChunkName: "ConventionDeServitude" */ "@/components/contrats/formulaires/ConventionDeServitude_form")
      } else if ((!!this.dialogEdit && this.editedItem.type === 'Bail') || (!!this.dialogAdd && this.contrat_type.class_name === 'Bail')) {
        return () => import(/* webpackChunkName: "Bail" */ "@/components/contrats/formulaires/Bail_form")
      } else if ((!!this.dialogEdit && this.editedItem.type === 'Mât de Mesure') || (!!this.dialogAdd && this.contrat_type.class_name === 'MatDeMesure')) {
        return () => import(/* webpackChunkName: "MatDeMesure" */ "@/components/contrats/formulaires/MatDeMesure_form")
      } else if ((!!this.dialogEdit) || (!!this.dialogAdd && !!this.contrat_type.class_name)) {
        return () => import(/* webpackChunkName: "DefaultTemplate" */ "@/components/contrats/formulaires/DefaultTemplate_form")
      }
    },
  },
}
</script>

<style scoped>

</style>