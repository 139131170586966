<template>
  <v-card class="mx-auto" max-width="1000">
    <v-data-table
        :headers="headers"
        :items="p_list"
        :search="search"
        :server-items-length="p_list_count"
        :options.sync="options"
        item-key="personne_id"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        :footer-props="{'items-per-page-options': [10, 30, 100],}"
        :key="componentKey"
    >
<!--              @click:row="goto"-->
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher un nom"
              @input="va_chercher"
          ></v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch class="pl-10" v-model="extra_data" label="Recherche élargie"></v-switch>
              </div>
            </template>
            <span>Affiche les données de la base de données entière <br/>plutôt que celles du projet uniquement</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <p-form ptype="pp">
            <template v-slot:boutons="{newItem}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="newItem">
                    <v-icon v-bind="attrs"
                            v-on="on" color="blue-grey">mdi-account-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nouvelle Personne Physique</span>
              </v-tooltip>
            </template>
          </p-form>
          <p-form ptype="pm">
            <template v-slot:boutons="{newItem}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="newItem">
                    <v-icon v-bind="attrs"
                            v-on="on" color="blue-grey">mdi-briefcase-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nouvelle Personne Morale</span>
              </v-tooltip>
            </template>
          </p-form>
        </v-toolbar>
      </template>
      <template v-slot:item.type="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :to="{name: item.type === 'pp' ? 'personne-physique': 'personne-morale', params: {id: item.personne_id}}">
              <v-icon small v-bind="attrs" v-on="on">{{ item.type === 'pp' ? 'mdi-account' : 'mdi-briefcase' }}</v-icon>
            </v-btn>
          </template>
          <span>Plus d'info sur cette personne</span>
        </v-tooltip>
      </template>
      <template v-slot:item.fav_adr="{ item }">
        <span>{{ item.fav_adr.ligne_1 }}</span><br v-if="item.fav_adr.ligne_1">
        <span>{{ item.fav_adr.ligne_2 }}</span><br v-if="item.fav_adr.ligne_2">
        <span>{{ item.fav_adr.code_postal }} {{ item.fav_adr.commune }}</span>
        <span v-if="item.fav_adr.pays !== 'France'"> {{ item.fav_adr.pays }}</span>
      </template>
      <template v-slot:item.actions="{item}">
        <p-form>
          <template v-slot:boutons="{deleteItem}">
            <v-tooltip left>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon @click.stop.prevent="deleteItem(item)">
                  <v-icon small v-bind="attrs" v-on="on" color="blue-grey">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </template>
        </p-form>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Pform from "@/components/Pform"
import {mapActions, mapState} from "vuex";

export default {
  name: "Personnes",
  components: {"p-form": Pform},
  data() {
    return {
      extra_data: false,
      options: {},
      titre_page: "Liste des personnes",
      loading: true,
      search: '',
      headers: [
        {text: 'Type', value: 'type', align: 'center', sortable: false},
        {text: 'Nom complet', align: 'start', value: 'nom_complet', sortable: false},
        {text: 'Téléphone', value: 'fav_tel', sortable: false},
        {text: 'E-mail', value: 'fav_mail', sortable: false},
        {text: 'Adresse', value: 'fav_adr', sortable: false},
        {text: 'Supprimer', value: 'actions', align: 'center', sortable: false},
      ],
      dialogAddPP: false,
      dialogAddPM: false,
    }
  },
  watch: {
    all_options: {
      handler() {
        this.loading = true
        this.get_p_list({
          "page": this.options.page,
          "itemsPerPage": this.options.itemsPerPage,
          "search": this.search,
          'extra': this.extra_data
        })
            .then(() => {
              this.loading = false
            })
      },
      deep: true,
    },
  },
  activated() {
    this.update_page(this.titre_page)
  },
  methods: {
    ...mapActions([
      'update_page',
    ]),
    ...mapActions('p', [
      'get_p_list',
    ]),
    // goto(row){
    //   this.$router.push({
    //     name: row.type === 'pp' ? 'personne-physique' : 'personne-morale',
    //     params: {id: row.personne_id}
    //   })
    // },
    va_chercher: function (input) {
      this.loading = true
      this.options.page = 1
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.get_p_list({
          "page": this.options.page,
          "itemsPerPage": this.options.itemsPerPage,
          "search": input,
          'extra': this.extra_data
        }).then(() => {
          this.loading = false
        })
      }, 500)
    }
  },
  computed: {
    ...mapState({
      p_list: state => state.p.p_list,
      p_list_count: state => state.p.p_list_count,
      componentKey: state => state.componentKey
    }),
    all_options(){
      return {...this.options, "extra_data": this.extra_data}
    }
  }
}


</script>

<style scoped>

</style>