<template>
  <v-card class="mx-auto mb-10 elevation-3" max-width="1000">
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="plot.commentaires"
          :item-key="plot.commentaire_parcelle_id"
          :sort-by="['maj_le_utc']"
          :sort-desc="[true]"
          no-data-text="Aucun commentaire"
      >
        <template v-slot:item.maj_le_utc="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{on}">
              <div v-on="on">{{ ISOtoUSR(date=item.maj_le_utc, strict=false) }}</div>
            </template>
            <span>{{format_timestamp(item.maj_le_utc)}}</span>
          </v-tooltip>

        </template>
        <template v-slot:item.actions="{item}">
            <v-tooltip left>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon @click="deleteComment(item)">
                  <v-icon small v-bind="attrs" v-on="on" color="blue-grey">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon @click="editComment(item)">
                  <v-icon small v-bind="attrs" v-on="on" color="blue-grey">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edition</span>
            </v-tooltip>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-container class="pa-0">
              <v-row class="pa-0">
                <v-col cols="4">
                  <v-toolbar-title class="text-h6">Suivi de parcelle</v-toolbar-title>
                </v-col>
                <v-col cols="4">
                </v-col>
                <v-col class="text-right">
                  <v-dialog v-model="dialogEdit" persistent max-width="600px">
                    <template v-slot:activator="{on: dialog}">
                      <v-tooltip left>
                        <template v-slot:activator="{on: tooltip}">
                          <v-btn icon v-on="{...tooltip, ...dialog}">
                            <v-icon large color="blue-grey">mdi-comment-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Ajouter un commentaire</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-title class="justify-center">
                        <span class="headline">Ajouter un commentaire</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form
                              ref="com_form"
                              v-model="com_form_valid"
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-textarea
                                    name="commentaire"
                                    :rules="[vr.required]"
                                    v-model="editedComment.commentaire"
                                    label="Commentaire"
                                >
                                </v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey" text @click="closecomment">Annuler</v-btn>
                        <v-btn type='submit' :disabled="!com_form_valid" color="blue-grey" text
                               @click="savecomment">Sauvegarder
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" persistent max-width="450px">
                    <v-card>
                      <v-card-title class="justify-center">Voulez-vous supprimer ce commentaire ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closecomment">Annuler</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteCommentConfirm">Oui</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import {format_functions} from "@/mixins/UtilityMixin";
export default {
  name: "BlocComment",
  mixins: [format_functions],
  data() {
    return {
      com_form_valid: false,
      dialogEdit: false,
      dialogDelete: false,
      headers: [
        {text: 'Commentaire', value: 'commentaire', sortable: false, width: '60%'},
        {text: 'Date', value: 'maj_le_utc'},
        {text: 'Auteur', value: 'auteur'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      editedComment: {},
      defaultComment: {},
    }
  },
  computed: {
    ...mapState({
      plot: state => state.plot.plot,
    }),
  },
  methods: {
    ...mapActions('plot', [
        'create_commentaire',
        'delete_commentaire',
        'update_commentaire'
    ]),

    deleteComment(item){
      this.editedComment = item
      this.dialogDelete = true
    },
    editComment(comment) {
      this.editedComment = Object.assign({}, comment)
      this.dialogEdit = true
    },

    deleteCommentConfirm() {
      this.delete_commentaire(this.editedComment)
        .then(() => {this.closecomment()})
    },

    savecomment() {
      if (this.editedComment["commentaire_parcelle_id"]) {
        this.update_commentaire(this.editedComment)
        .then(() => {this.closecomment()})
      } else {
        this.create_commentaire(this.editedComment)
            .then(() => {this.closecomment()})
      }
    },
    closecomment() {
      this.dialogEdit = false
      this.dialogDelete = false
      !!this.$refs.com_form && this.$refs.com_form.resetValidation()
      this.$nextTick(() => {
        this.editedComment = Object.assign({}, this.defaultComment)
      })
    },
  }
}
</script>

<style scoped>

</style>