<template>
  <v-card class="mx-auto" max-width="400px" elevation="3">
    <v-card-title class="justify-center">Bonjour</v-card-title>
    <v-card-subtitle class="text-center">Choisissez un projet</v-card-subtitle>
    <v-card-text>
      <v-list>
        <v-list-item v-for="project in project_list" :key="project.projet_id" @click="gotoproject(project)">
          <v-list-item-content>
            <v-list-item-title v-text="project.nom"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex"
export default {
  name: "Projets",
  data () {
      return {
        titre_page: '',
        headers: [
          { text: 'Projet', align: 'start', sortable: false, value: 'nom'},
        ],
      }
    },
  computed: {
    ...mapGetters('user', [
      'LoggedIn'
    ]),
    ...mapState({
      project_list: state => state.project.project_list,
    }),
  },
  created() {
    this.update_page(this.titre_page),
    this.get_project_list()
    this.set_active_project({})
  },
  methods: {
    ...mapActions([
      'update_page',
    ]),
    ...mapActions('project', [
      'get_project_list',
      'set_active_project'
    ]),

    gotoproject(item){
      this.set_active_project(item)
        .then(() => {
          this.$router.push({
            name: 'personnes',
          })
        })
    }
  }
}
</script>

<style scoped>

</style>