import moment from "moment";


function is_personne_unique(v, array, editedItem) {
  let liste = array.filter(element => element.contratpersonne_id !== editedItem.contratpersonne_id)
  return !!v && !liste.some(element => element.personne_id === v.personne_id)
}

function is_parcelle_unique(v, array, editedItem) {
  let liste = array.filter(element => element.contratparcelle_id !== editedItem.contratparcelle_id)
  return !!v && !liste.some(element => element.parcelle_id === v.parcelle_id)
}

function is_droit_unique(v, array, editedItem) {
  let liste = array.filter(element => element.type_droit_id !== editedItem.type_droit_id)
  return !!v && !liste.some(element => element.description.toLowerCase() === v.toLowerCase())
}

function is_statut_unique(v, array, editedItem) {
  let liste = array.filter(element => element.statutparcelle_id !== editedItem.statutparcelle_id)
  return !!v && !liste.some(element => element.description.toLowerCase() === v.toLowerCase())
}

function is_type_fichier_unique(v, array, editedItem) {
  let liste = array.filter(element => element.typefichier_id !== editedItem.typefichier_id)
  return !!v && !liste.some(element => element.nom_type.toLowerCase() === v.toLowerCase())
}

function is_unique(v, array, editedItem, idKey, idKey2) {
  let liste = array.filter(element => element[idKey2] !== editedItem[idKey2])
  return (!!v && !liste.some(element => element[idKey] === v[idKey2]))
}

export const validation_rules = {
  data() {
    return {
      vr: {
        required: v => !!v || v === 0 || 'Champ obligatoire',
        personne_required: v => !!v.nom_complet || 'Champ obligatoire',
        parcelle_required: v => !!v.iduu || 'Champ obligatoire',
        xlfile: v => (!!v && ['xls', 'xlsx'].includes(v.name.split('.').pop().toLowerCase())) || 'Entrez un fichier Excel (.xls .xlsx)',

        personne_unique: function (array, editedItem) {return v => is_personne_unique(v, array, editedItem) || 'Personne déjà présente'},
        parcelle_unique: function (array, editedItem) {return v => is_parcelle_unique(v, array, editedItem) || 'Parcelle déjà présente'},
        droit_unique: function (array, editedItem) {return v => is_droit_unique(v, array, editedItem) || 'Droit déjà existant'},
        statut_unique: function (array, editedItem) {return v => is_statut_unique(v, array, editedItem) || 'Statut déjà existant'},
        type_fichier_unique: function (array, editedItem) {return v => is_type_fichier_unique(v, array, editedItem) || 'Type de pièce jointe déjà existante'},
        unique: function (array, editedItem, idKey, idKey2) {return v => is_unique(v, array, editedItem, idKey, idKey2) || 'Déjà présent dans le contrat'},

        date_valid: v => !v || moment(v, ['DD/MM/YYYY', 'DD-MM-YYYY'], true).isValid() || 'date non valide',
        email: v => /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(v) || 'Entrer une adresse mail valide',
        entier_positif: v => (Number.isInteger(v) && v >= 0) || 'Le nombre de parts doit être un entier positif',
        maxlength: function (max) {return v => !v ||  v.length <= max || `Le nom du statut ne peut dépaser ${max} caractères`},

      },
    }
  },
}