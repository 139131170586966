import CPMSService from "@/services/CPMSService";

export const namespaced = true

export const state = {
  editedItem: {},
  defaultItem: {}
}

export const mutations = {
  INIT_EI(state, editedItem) {
    state.editedItem = editedItem
  },
  FLUSH_EI(state) {
    state.editedItem = {}
  },
  UPDATE_EI(state, {field, value}){
    state.editedItem[field] = value
  }
}

export const actions = {
  init_ei({state, commit}, editedItem) {
    commit('INIT_EI', editedItem)
  },
  flush_ei({state, commit}) {
    commit('FLUSH_EI')
  },
  update_ei({state, commit}, {field, value}){
    commit('UPDATE_EI', {'field': field, 'value': value})

  },




}

export const getters = {}