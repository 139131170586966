import CPMSService from "@/services/CPMSService";
import router from "@/router";

export const namespaced = true

export const state = {
  'plot': {},
  'plot_list': [],
  'plot_list_count': 0,
}

export const mutations = {
  UPDATE_PLOT_LIST(state, data) {
    state.plot_list = data.results
    state.plot_list_count = data.count
  },
  FLUSH_PLOT(state) {
    state.plot = {}
  },
  UPDATE_PLOT(state, plot) {
    state.plot = {...state.plot, ...plot}
  },
  PUSH_COMMENT(state, comment) {
    state.plot.commentaires.push(comment)
  },
  POP_COMMENT(state, comment){
    const index = state.plot.commentaires.findIndex(element => element["commentaire_parcelle_id"] === comment["commentaire_parcelle_id"])
    state.plot.commentaires.splice(index, 1)
  },
  UPDATE_COMMENT(state, comment){
    const index = state.plot.commentaires.findIndex(element => element["commentaire_parcelle_id"] === comment["commentaire_parcelle_id"])
    state.plot.commentaires.splice(index, 1, comment)
  },
  DELETE_ITEM(state, {index, array}) {
    array.splice(index, 1)
  },
  UPDATE_ITEM(state, {index, array, item}) {
    array.splice(index, 1, item)
  },
  ADD_ITEM(state, {item, array}) {
    array.push(item)
  },
}

export const actions = {
  get_plot_list({commit}, {page=1, itemsPerPage=10, search="", extra=false}) {
    const offset = (page - 1) * itemsPerPage
    const limit = itemsPerPage
    CPMSService.getPlotList({offset: offset, limit: limit, search: search, extra: extra})
      .then(response => {
        commit("UPDATE_PLOT_LIST", response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  get_plot({state, commit}, id) {
    if (this.state.plot.plot.parcelle_id !== id) {
      commit('FLUSH_PLOT')
    }
    return new Promise((resolve, reject) => {
      CPMSService.getPlotDetail(id)
        .then(response => {
          commit('UPDATE_PLOT', response.data)
          resolve(response.data)
        }).catch(
        error => {
          console.log(error)
          reject(error)
        })
    })
  },
  update_statut({state, commit}, data) {
    return new Promise((resolve, reject) => {
      CPMSService.updateStatutParcelle(data)
        .then(response => {
          commit('UPDATE_PLOT', response.data)
          resolve()
        }).catch(
        error => {
          console.log(error)
          reject(error)
        })
    })
  },
  create_commentaire({state, commit}, comment){
    comment.parcelle = this.state.plot.plot.parcelle_id
    CPMSService.addPlotComment(comment)
    .then(response => {
      commit('PUSH_COMMENT', response.data)
    }).catch(error => {
      console.log(error)
    })
  },
  delete_commentaire({state, commit}, comment) {
    return new Promise((resolve, reject) => {
      CPMSService.deletePlotComment(comment['commentaire_parcelle_id'])
        .then(() => {
          commit('POP_COMMENT', comment)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
      })
    })
  },
  update_commentaire({commit}, comment){
    return new Promise((resolve, reject) => {
      CPMSService.updatePlotComment(comment)
        .then( response => {
          commit('UPDATE_COMMENT', response.data)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
      })
    })
  },
  delete_item({state, commit}, {item, array, idKey, endpoint}) {
    CPMSService.deleteItem(item[idKey], endpoint)
      .then(() => {
        const index = array.findIndex(element => element[idKey] === item[idKey])
        commit('DELETE_ITEM', {"index": index, "array": array})
      }).catch(error => {
      console.log(error)
    })
  },
  update_item({state, commit}, {idKey, array, item, endpoint}) {
    return new Promise((resolve, reject) => {
      CPMSService.updateItem(item[idKey], item, endpoint)
        .then(response => {
          const index = array.findIndex(element => element[idKey] === response.data[idKey])
          commit('UPDATE_ITEM', {"array": array, "index": index, "item": response.data})
          resolve(response)
        }).catch(error => {
          console.log(error)
        reject(error)
      })
    })
  },
  add_item({state, commit}, {item, array, endpoint}) {
    return new Promise((resolve, reject) => {
      CPMSService.createItem(item, endpoint)
        .then(response => {
          commit('ADD_ITEM', {"item": response.data, "array": array})
          resolve(response)
        }).catch(error => {
          reject(error)
      })
    })
  },
  download_plots({dispatch}, {code_insee}){
    return new Promise((resolve, reject) =>{
      CPMSService.downloadCadastre(code_insee).then((response) => {
        dispatch('get_plot_list', {}).then(() => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    })
  }
}

export const getters = {}