import moment from "moment";

const USR_format = 'DD/MM/YYYY'
const ISO_format = 'YYYY-MM-DD'
export const format_functions = {
  methods: {
    format_date_toISO(date) {
      // créer un string au format YYYY-MM-DD à partir d'un objet moment/date
      return !!date ? date.format(ISO_format) : null
    },
    format_date_toUSR(date) {
      // créer un string au format USR_format à partir d'un objet moment/date
      return !!date ? date.format(USR_format) : null
    },
    parse_date_fromUSR(date) {
      // créer un objet moment/date à partir d'un string au format USR_format
      return !!date ? moment(date, [USR_format], true) : null
    },
    parse_date_fromISO(date, strict= true) {
      // créer un objet moment/date à partir d'un string au format ISO
      return !!date ? moment(date, [ISO_format], strict) : null
    },
    ISOtoUSR(date, strict=true){
      return this.format_date_toUSR(this.parse_date_fromISO(date=date, strict= strict))
    },
    USRtoISO(date, strict=true){
      return this.format_date_toISO(this.parse_date_fromUSR(date=date, strict= strict))
    },
    format_timestamp: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    format_duree: function (duree) {
      return moment.duration(duree).humanize()
      // return duree
    },
  }
}