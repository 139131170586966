<template>
  <v-dialog
      v-model="dialogEditMain"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{on: dialog}">
      <v-tooltip right close-delay="500" open-delay="500">
        <template v-slot:activator="{on: tooltip}">
          <v-icon v-on="{...dialog, ...tooltip}">mdi-pencil</v-icon>
        </template>
        <span>Modifier la liste des statuts</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto" max-width="600">
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="statut_parcelle_list"
            sort-by="description"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer/>
              <v-toolbar-title class="text-h6">Statuts Parcelles</v-toolbar-title>
              <v-spacer/>
              <v-dialog
                  v-model="dialogEdit"
                  persistent
                  max-width="400px"
              >
                <template v-slot:activator="{on: dialog}">
                  <v-tooltip left>
                    <template v-slot:activator="{on: tooltip}">
                      <v-btn icon v-on="{...dialog, ...tooltip}">
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Créer un nouveau statut</span>
                  </v-tooltip>
                </template>
                <v-form
                    v-model="form_valid"
                    @submit.prevent="saveItem"
                    ref="form"
                >
                  <v-card>
                    <v-card-title class="justify-center">Créer/Modifier un statut</v-card-title>
                    <v-card-text>
                      <v-text-field
                          :rules='[vr.required, vr.statut_unique(statut_parcelle_list, editedItem), vr.maxlength(30)]'
                          v-model="editedItem.description"
                          label="Intitulé"
                          type="text"
                          counter="30"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="blue-grey" text @click.stop.prevent="close">Annuler</v-btn>
                      <v-btn type='submit' color="blue-grey" :disabled="!form_valid || form_unmodified" text>
                        Sauvegarder
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-dialog
                  v-model="dialogDelete"
                  persistent
                  max-width="550px"
              >
                <v-card>
                  <v-card-title class="justify-center">Voulez-vous vraiment supprimer ce statut ?</v-card-title>
                  <v-card-title class="justify-center font-italic">{{ editedItem.description }}</v-card-title>
                  <v-card-text class="justify-center">
                    <p class="text-justify">
                      Cette modification affectera toutes les parcelles possédant ce statut dans tous les projets.
                      Si vous souhaitez continuer, sélectionnez un statut de remplacement et cliquez sur
                      "Supprimer". Sinon cliquez sur "Annuler"
                    </p>
                    <br/>
                    <v-select
                        :items="statut_parcelle_list_tronque"
                        v-model="replacement_statut"
                        item-text='description'
                        label="Statut de remplacement"
                        :menu-props="{offsetY: true}"
                        return-object
                    ></v-select>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey" text @click="close">Annuler</v-btn>
                    <v-btn color="blue-grey"
                           :disabled="!replacement_statut.statutparcelle_id"
                           text
                           @click="deleteItemConfirm">Supprimer
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click.stop.prevent="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click.stop.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey" text @click="dialogEditMain = false">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CPMSService from "@/services/CPMSService";
import {isEqual} from "lodash";

export default {
  name: "TableStatutParcelles",
  data: function () {
    return {
      form_valid: true,
      dialogEditMain: false,
      dialogEdit: false,
      dialogDelete: false,
      editedItem: {description: '', statutparcelle_id: null},
      defaultItem: {description: '', statutparcelle_id: null},
      replacement_statut: {description: 'Non renseigné', statutparcelle_id: '5'},
      headers: [
        {text: 'Statut', value: 'description'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
    }
  },
  methods: {
    ...mapActions('statut', [
      'get_statut_parcelle_list',
      'delete_statut_parcelle',
      'update_statut_parcelle',
      'add_statut_parcelle'

    ]),
    close() {
      this.dialogEdit = false
      this.dialogDelete = false
      this.editedItem = {...this.defaultItem}
      this.replacement_statut = {}
      !!this.$refs.form && this.$refs.form.resetValidation()

    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true

    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.delete_statut_parcelle({to_delete: this.editedItem, to_replace_with: this.replacement_statut})
          .then(() => {
            this.close()
          })
    },
    saveItem() {
      if (!!this.editedItem.statutparcelle_id) {
        this.update_statut_parcelle(this.editedItem)
          .then(() => {this.close()})
      } else {
        this.add_statut_parcelle(this.editedItem)
          .then(() => {this.close()})
      }
    },
  },
  created() {
    this.get_statut_parcelle_list()
  },
  computed: {
    ...mapState({
      statut_parcelle_list: state => state.statut.statut_parcelle_list
    }),
    statut() {
      return this.plot.statut
    },
    statut_parcelle_list_tronque() {
      return this.statut_parcelle_list.filter(element => element.statutparcelle_id !== this.editedItem.statutparcelle_id)
    },
    form_unmodified() {
      const originalItem = this.editedItem.statutparcelle_id ? this.statut_parcelle_list.find(element => element.statutparcelle_id === this.editedItem.statutparcelle_id) : {}
      return isEqual(this.editedItem, originalItem)
    },

  },
}
</script>

<style scoped>

</style>