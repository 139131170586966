<template>
  <div>
    <div class="text-center text-h3 ma-10" data-testid="bienvenue">Bienvenue</div>
    <v-card v-if="!LoggedIn" class="mx-auto" max-width="400px" elevation="3" data-testid="login-card">
      <v-form
          v-model="form_valid"
          @submit.prevent="login2"
          data-testid="login-form"
      >
        <v-card-title class="justify-center">Veuillez entrer vos identifiants</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    placeholder=" "
                    autocomplete="username"
                    :rules="[vr.required, vr.email]"
                    v-model="email"
                    label="E-mail"
                    type="text"
                    data-testid="field-username"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    :rules=[vr.required]
                    v-model="password"
                    label="Mot de passe"
                    :type="eye ? 'text' : 'password'"
                    :append-icon="eye ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="eye = !eye"
                    autocomplete="current-password"
                    placeholder=" "
                    data-testid="field-pwd"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type='submit' :disabled="!form_valid" color="blue darken-1" text data-testid="submit-btn">Login</v-btn>
          <v-btn color="blue darken-1" text @click="" text data-testid="register-btn">Register</v-btn>
<!--          <v-btn color="blue darken-1" text @click="register" text data-testid="register-btn">Register</v-btn>-->
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>

    </v-card>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import router from "@/router";
export default {
  name: "LoginUSer",
  data: function () {
    return {
      email: '',
      password: '',
      form_valid: '',
      eye: false,
    }
  },
  methods: {
    login2() {

      this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password
      }).then(() => {
        !!this.$route.query.from ? router.push(this.$route.query.from): router.push({name: 'projets'})
        // router.push({name: 'projets'})
      })
    },
    register(){
      this.$router.push({name: 'register'})
    }
  },
  computed: {
    ...mapGetters('user', [
      'LoggedIn'
    ])
  }
}
</script>

<style scoped>

</style>