<template>
  <v-autocomplete
      :value="commune"
      :loading="loading"
      :items="commune_list"
      :item-text="gen_text"
      return-object
      :search-input.sync="search"
      class="mx-4"
      flat
      hide-no-data
      @input="$emit('input', $event)"
      label="Entrez le nom d'une Commune"
      persistent-hint
      no-filter
  ></v-autocomplete>
</template>

<script>
import axios from "axios";

export default {
  name: "BaseCommuneSelect",
  props: {
    'commune': {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      search: '',
      loading: false,
      commune_list: [],
      timer: null,
    }
  },
  watch: {
    search(val) {
      val  && this.va_chercher(val)
    },
  },
  methods: {
    gen_text(item){
      if (item.nom && item.departement){return `${item.nom} (${item.departement.code})`}
      else if (item.nom){return item.nom}
      else {return ''}
    },
    va_chercher(input) {
      this.loading = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const url = `https://geo.api.gouv.fr/communes?nom=${input}&fields=region,departement,population&limit=10`
        axios.get(url).then((response) => {
          this.commune_list = response.data.filter(el =>  !['96', '97', '75'].includes(el.code.slice(0,2)))
          this.loading = false
        })
      }, 500)
    },
  },
}
</script>

<style scoped>

</style>