import CPMSService from "@/services/CPMSService";

export const namespaced = true

export const state = {
  'type_droit_list': [],
}

export const mutations = {
  UPDATE_TYPE_DROIT_LIST(state, data) {
    state.type_droit_list = data
  },
  DELETE_TYPE_DROIT(state, to_delete) {
    const index = state.type_droit_list.findIndex(el => el.type_droit_id === to_delete.type_droit_id)
    state.type_droit_list.splice(index, 1)
  },
  UPDATE_TYPE_DROIT(state, to_update) {
    const index = state.type_droit_list.findIndex(el => el.type_droit_id === to_update.type_droit_id)
    state.type_droit_list.splice(index, 1, to_update)
  },
  ADD_NEW_DROIT(state, new_droit) {
    state.type_droit_list.push(new_droit)
  }
}

export const actions = {
  get_type_droit_list({commit, state}) {
    CPMSService.getTypeDroitList()
      .then(response => {
        commit("UPDATE_TYPE_DROIT_LIST", response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  delete_type_droit({commit, dispatch, rootState}, {to_delete, to_replace_with}) {
    CPMSService.deleteTypeDroit(to_delete.type_droit_id, to_replace_with.type_droit_id)
      .then(() => {
        rootState.p.p.personne_id && dispatch('p/get_p', rootState.p.p.personne_id, {root: true})
        rootState.plot.plot.parcelle_id && dispatch('plot/get_plot', rootState.plot.plot.parcelle_id, {root: true})
        commit("DELETE_TYPE_DROIT", to_delete)
      }).catch(error => {
      console.log(error)
    })
  },
  update_type_droit({commit, dispatch, rootState}, to_update) {
    CPMSService.updateItem(to_update.type_droit_id, to_update, 'type-droit')
      .then(response => {
        rootState.p.p.personne_id && dispatch('p/get_p', rootState.p.p.personne_id, {root: true})
        rootState.plot.plot.parcelle_id && dispatch('plot/get_plot', rootState.plot.plot.parcelle_id, {root: true})
        commit("UPDATE_TYPE_DROIT", response.data)
      }).catch(error => {
      console.log(error)
    })
  },
  add_type_droit({commit}, new_droit) {
    CPMSService.createItem(new_droit, 'type-droit')
      .then(response => {
        commit('ADD_NEW_DROIT', response.data)
      }).catch(error => {
      console.log(error)
    })
  },
  upload_file({dispatch}, data) {
    return new Promise((resolve, reject) => {
      CPMSService.uploadDroitFile(data).then((response) => {
        Promise.all([
          dispatch('p/get_p_list', {}, {root: true}),
          dispatch('plot/get_plot_list', {}, {root: true})
        ]).then(()=>{
          resolve(response)
        })

      }).catch(error => {
        reject(error)
      })
    })
  },

}

export const getters = {}