import Vue from 'vue'
import Vuex from 'vuex'
import CPMSService from "@/services/CPMSService"
import * as plot from '@/store/modules/plot.js'
import * as p from '@/store/modules/p.js'
import * as c from '@/store/modules/c.js'
import * as project from '@/store/modules/project.js'
import * as user from '@/store/modules/user.js'
import * as edition from '@/store/modules/edition'
import * as droit from '@/store/modules/droit'
import * as statut from '@/store/modules/statut'
import * as types_fichiers from '@/store/modules/types_fichiers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    plot,
    p,
    c,
    project,
    user,
    edition,
    droit,
    statut,
    types_fichiers,
  },
  state: {
    'page': '',
    'componentKey': 0,
  },
  mutations: {
    UPDATE_PAGE(state, name) {
      state.page = name
    },

    INCREMENT_COMPONENT_KEY(state) {
      state.componentKey += 1
    },
  },
  actions: {
    increment_component_key({commit}) {
      commit('INCREMENT_COMPONENT_KEY')
    },
    update_page({commit}, name) {
      commit('UPDATE_PAGE', name)
    },
  },
  getters: {
  }
})

