import CPMSService from "@/services/CPMSService";

export const namespaced = true

export const state = {
  'statut_parcelle_list': [],
}

export const mutations = {
  UPDATE_STATUT_PARCELLE_LIST(state, data) {
    state.statut_parcelle_list = data
  },
  DELETE_STATUT_PARCELLE(state, to_delete) {
    const index = state.statut_parcelle_list.findIndex(el => el.statutparcelle_id === to_delete.statutparcelle_id)
    state.statut_parcelle_list.splice(index, 1)
  },
  UPDATE_STATUT_PARCELLE(state, to_update) {
    const index = state.statut_parcelle_list.findIndex(el => el.statutparcelle_id === to_update.statutparcelle_id)
    state.statut_parcelle_list.splice(index, 1, to_update)
  },
  ADD_STATUT_PARCELLE(state, new_droit) {
    state.statut_parcelle_list.push(new_droit)
  }
}

export const actions = {
  get_statut_parcelle_list({commit}) {
    CPMSService.getStatutParcelleList()
      .then(response => {
        commit("UPDATE_STATUT_PARCELLE_LIST", response.data)
      }).catch(error => {
      console.log(error)
    })
  },

  delete_statut_parcelle({commit, dispatch, rootState}, {to_delete, to_replace_with}) {
    CPMSService.deleteStatutParcelle(to_delete.statutparcelle_id, to_replace_with.statutparcelle_id)
      .then(() => {
        rootState.plot.plot.parcelle_id && dispatch('plot/get_plot', rootState.plot.plot.parcelle_id, {root: true})
        commit("DELETE_STATUT_PARCELLE", to_delete)
      }).catch(error => {
      console.log(error)
    })
  },

  update_statut_parcelle({commit, dispatch, rootState}, to_update) {
    CPMSService.updateItem(to_update.statutparcelle_id, to_update, 'statut-parcelle')
      .then(response => {
        rootState.plot.plot.parcelle_id && dispatch('plot/get_plot', rootState.plot.plot.parcelle_id, {root: true})
        commit("UPDATE_STATUT_PARCELLE", response.data)
      }).catch(error => {
      console.log(error)
    })
  },

  add_statut_parcelle({commit}, new_statut) {
    CPMSService.createItem(new_statut, 'statut-parcelle')
      .then(response => {
        commit('ADD_STATUT_PARCELLE', response.data)
      }).catch(error => {
      console.log(error)
    })
  }
}

export const getters = {}