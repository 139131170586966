<template>
  <div>
    <v-select
        :items="statut_parcelle_list"
        :value="statut"
        @input="savestatut"
        item-text='description'
        item-value="description"
        label="Statut de la parcelle"
        :loading="loading"
        :menu-props="{offsetY: true}"
        ref="statutselect"
    >
      <template v-slot:append-outer>
        <TableStatutParcelles :key="componentKey" />
      </template>
    </v-select>
  </div>
</template>

<script>
import CPMSService from "@/services/CPMSService";
import {mapActions, mapState} from "vuex";
import TableStatutParcelles from "@/components/TableStatutParcelles";
export default {
  name: "BaseStatutParcelleSelect",
  components: {TableStatutParcelles},
  data: function () {
    return {
      loading: false,
    }
  },
  created() {
    this.get_statut_parcelle_list()
  },
  methods: {
    ...mapActions('plot', [
      'update_statut',
    ]),
    ...mapActions('statut', [
      'get_statut_parcelle_list',

    ]),
    savestatut(val) {
      this.loading = true
      this.update_statut({parcelle_id: this.plot.parcelle_id, statut: val})
          .then(response => {
            this.loading = false
          }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
  },
  computed: {
    ...mapState({
      statut_parcelle_list: state => state.statut.statut_parcelle_list,
      plot: state => state.plot.plot,
      componentKey: state => state.componentKey
    }),
    statut() {
      return this.plot.statut
    },
  },
}
</script>

<style scoped>

</style>