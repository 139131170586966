import CPMSService from "@/services/CPMSService";
import router from "@/router";
import VueXService from "@/services/CPMSService"

export const namespaced = true

export const state = {
  'c': {},
  'c_list': [],
  'c_list_count': 0,
}

export const mutations = {
  UPDATE_C_LIST(state, data) {
    state.c_list = data.results
    state.c_list_count = data.count
  },
  DELETE_C(state, item) {
    state.c = {}
    const index = state.c_list.findIndex(element => element.contrat_id === item.contrat_id)
    state.c_list.splice(index, 1)
    state.c_list_count--
  },
  FLUSH_C(state) {
    state.c = {}
  },
  UPDATE_C(state, c) {
    state.c = {...state.c, ...c}
  },
  ADD_C(state, c) {
    state.c_list.push(c)
    state.c = c
  },
  DELETE_ITEM(state, {index, array}) {
    array.splice(index, 1)
  },
  UPDATE_ITEM(state, {index, array, item}) {
    array.splice(index, 1, item)
  },
  ADD_ITEM(state, {item, array}) {
    array.push(item)
  },
}
export const actions = {
  get_c_list({commit}, {page, itemsPerPage, search, extra}) {
    const offset = (page - 1) * itemsPerPage
    const limit = itemsPerPage
    CPMSService.getAgreementList({offset: offset, limit: limit, search: search, extra: extra})
      .then(response => {
        commit("UPDATE_C_LIST", response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  get_c({state, commit}, id) {
    if (this.state.c.contrat_id !== id) {
      commit('FLUSH_C')
    }
    return new Promise((resolve, reject) => {
      CPMSService.getAgreementDetail(id)
        .then(response => {
          commit('UPDATE_C', response.data)
          resolve(response.data)
        }).catch(
        error => {
          console.log(error)
          reject(error)
        })
    })
  },
  delete_c({state, commit}, item) {
    CPMSService.deleteItem(item.contrat_id, "contrat")
      .then(() => {
        commit('DELETE_C', item)
      }).catch(error => {
      console.log(error)
    })
  },
  update_c({state, commit}, item) {
    return new Promise((resolve, reject) => {
      CPMSService.updateItem(item.contrat_id, item,"contrat")
        .then(response => {
          commit('UPDATE_C', response.data)
          resolve(response)
        }).catch(error => {
          console.log(error)
        reject(error)
      })
    })
  },
  add_c({state, commit}, item) {
    return new Promise((resolve, reject) => {
      CPMSService.createItem(item, 'contrat/create')
        .then(response => {
          commit('ADD_C', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
      })
    })
  },
  delete_item({state, commit}, {item, array, idKey, endpoint}) {
    CPMSService.deleteItem(item[idKey], endpoint)
      .then(() => {
        const index = array.findIndex(element => element[idKey] === item[idKey])
        commit('DELETE_ITEM', {"index": index, "array": array})
      }).catch(error => {
      console.log(error)
    })
  },
  update_item({state, commit}, {idKey, array, item, endpoint}) {
    return new Promise((resolve, reject) => {
      CPMSService.updateItem(item[idKey], item, endpoint)
        .then(response => {
          const index = array.findIndex(element => element[idKey] === response.data[idKey])
          commit('UPDATE_ITEM', {"array": array, "index": index, "item": response.data})
          resolve(response)
        }).catch(error => {
          console.log(error)
        reject(error)
      })
    })
  },
  add_item({state, commit}, {item, array, endpoint}) {
    return new Promise((resolve, reject) => {
      CPMSService.createItem(item, endpoint)
        .then(response => {
          commit('ADD_ITEM', {"item": response.data, "array": array})
          resolve(response)
        }).catch(error => {
          reject(error)
      })
    })
  },

}

export const getters = {}