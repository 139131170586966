<template>
  <v-card class="mx-auto" max-width="1000">
    <v-data-table
        :headers="headers"
        :items="this.c.parcelles"
        disable-pagination
        hide-default-footer
        sort-by="parcelle.iduu"
        class="elevation-2"
        no-data-text="Aucune parcelle"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">Parcelles</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="justify-center">Voulez-vous supprimer cette Parcelle ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeItem">Annuler</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEdit" persistent max-width="500px">
            <template v-slot:activator="{on: dialog}">
              <v-tooltip left>
                <template v-slot:activator="{on: tooltip}">
                  <v-btn icon v-on="{...dialog, ...tooltip}">
                    <v-icon large color="blue-grey">mdi-map-plus</v-icon>
                  </v-btn>
                </template>
                <span>Ajouter/modifier une parcelle</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-form
                  ref="form"
                  v-model="form_valid"
                  @submit.prevent="saveItem"
              >
                <v-card-title class="justify-center">
                  <span class="headline">Ajouter/Modifier une parcelle</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <BaseParcelleSelect
                        :value="editedItem.parcelle"
                        label="Parcelle (iduu)"
                        @input="(data) => {editedItem.parcelle = data; editedItem.parcelle_id = data.parcelle_id}"
                        :rules="[vr.parcelle_required, vr.parcelle_unique(c.parcelles, editedItem)]"
                      ></BaseParcelleSelect>
                    </v-row>
                    <v-row>
                      <v-spacer/>
                      <error-manager :serverErrors="serverErrors"/>
                      <v-spacer/>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-grey" text @click="closeItem">Annuler</v-btn>
                  <v-btn type='submit' :disabled="!form_valid" color="blue-grey" text>Sauvegarder
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ErrorManager from "@/components/ErrorManager";
import CPMSService from "@/services/CPMSService";

export default {
  name: "TableParcelles",
  components: {'error-manager': ErrorManager},
  data() {
    return {
      headers: [
        {text: 'IDUU', value: 'parcelle.iduu', align: "center", sortable: false},
        {text: 'Commune', value: 'parcelle.nom_com', sortable: false},
        {text: 'Préfixe', value: 'parcelle.prefixe', sortable: false},
        {text: 'Section', value: 'parcelle.section', sortable: false},
        {text: 'Numéro', value: 'parcelle.numero', sortable: false},
        {text: 'Supprimer', value: 'actions', sortable: false},
      ],
      search: '',
      editedItem: {},
      defaultItem: {},
      dialogDelete: false,
      dialogEdit: false,
      form_valid: true,
      serverErrors: {},
    }
  },
  computed: {
    ...mapState({
      c: state => state.c.c,
    }),
  },
  methods: {
    ...mapActions('c', [
      'delete_item',
      'update_item',
      'add_item'
    ]),
    closeItem() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.$refs.form && this.$refs.form.resetValidation()
      this.serverErrors = {}
      this.dialogEdit = false
      this.dialogDelete = false
    },
    editItem: function (item) {
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },
    addItem: function () {
      this.dialogEdit = true
    },
    saveItem() {
      this.editedItem.contrat = this.c.contrat_id
      if (this.editedItem["contratparcelle_id"]) {
        this.update_item({
          "array": this.c.parcelles,
          "item": this.editedItem,
          "idKey": "contratparcelle_id",
          "endpoint": "contrat-parcelle"
        }).then((response) => {
          this.closeItem()
        }).catch(error => {
          if (!!error.response && error.response.status === 400) {
            this.serverErrors = error.response.data
          }
        })
      } else {
        this.add_item({
          "item": this.editedItem,
          "array": this.c.parcelles,
          "endpoint": "contrat-parcelle"
        }).then((response) => {
          this.closeItem()
        }).catch(error => {
          if (!!error.response && error.response.status === 400) {
            this.serverErrors = error.response.data
          }
        })
      }
    },
    deleteItem: function (item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.delete_item({
        "item": this.editedItem,
        "array": this.c.parcelles,
        "idKey": "contratparcelle_id",
        "endpoint": "contrat-parcelle"
      }).then((response) => {
        this.closeItem()
      })
    },
  },
}
</script>

<style scoped>

</style>